import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { setAccessToken } from "../redux/featuresSlice/commonSlice";

const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const Layout = () => {
    const statusLoading = useSelector((state) => state.common.statusLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken");
        if (token) {
            dispatch(setAccessToken(token));
        }
    }, [dispatch]);

    return (
        <div>
           {statusLoading && (
                <div style={overlayStyle}>
                    <BounceLoader color="#3534ff" />
                </div>
            )}
            <Outlet />
            <ToastContainer />
        </div>
    );
};

export default Layout;
