import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CardPreview from "./CardPreview";
import CardSpecifications from "./CardSpecifications";
import CardSpecificationsTypeSecond from "./CardSpecificationsTypeSecond";
import CardSpecificationTypeThird from "./CardSpecificationTypeThird";
import { extractQuantity } from "../../../utils/helper/formatters";
import Button from "../../../components/common/Button/Button";
import {
    usePostBusinessCardOrdersSprintsMutation,
    usePostOrdersPrintsMutation,
} from "../../../services/apiSlice";
import {
    findSpecByLabel,
    getSelectedValue,
} from "../../../utils/helper/function";
import { toast } from "react-toastify";

export default function CardQuantitySelector({ selectedCardType }) {
    const navigate = useNavigate();
    const [advisoryText, setAdvisoryText] = useState("");

    // Redux state
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const [postBusinessCardsOrdersSprints] =
        usePostBusinessCardOrdersSprintsMutation();
    const [postOrderPrints] = usePostOrdersPrintsMutation();

    const handleInputChange = (value) => {
        setAdvisoryText(value);
    };

    const [selectedDataGeneral, setSelectedDataGeneral] = useState({});
    const handleSelectedDataChangeGeneral = (data) => {
        setSelectedDataGeneral(data);
    };

    const [selectedDataPremium, setSelectedDataPremium] = useState([]);
    const handleSelectedDataChange = (data) => {
        setSelectedDataPremium(data);
    };

    const findSpecAndCreateBody = (selectedData, specs) => {
        const paperTypeObject = findSpecByLabel(selectedData?.specs, "용지");
        const printsCountObject = findSpecByLabel(
            selectedData?.specs,
            "인쇄도수",
        );
        const coatingObject = findSpecByLabel(selectedData?.specs, "코팅");
        const sizeObject = findSpecByLabel(selectedData?.specs, "규격");
        const postProcessingObject = findSpecByLabel(
            selectedData?.specs,
            "후가공",
        );
        const postQuantityObject = findSpecByLabel(selectedData?.specs, "수량");

        return {
            userId: userId,
            businessCardId: 4,
            printOrderType: selectedCardType?.name ?? "일반명함",
            cardMaterialType: selectedData?.selectedOption ?? "",
            paperType: getSelectedValue(paperTypeObject),
            printMethod: getSelectedValue(printsCountObject),
            coatingType: coatingObject?.value || "",
            size: sizeObject?.value || "",
            postProcessing: getSelectedValue(postProcessingObject),
            quantity: postQuantityObject?.selectedValue
                ? extractQuantity(postQuantityObject?.selectedValue)
                : extractQuantity(postQuantityObject?.value[0]),
            totalPrice: selectedData?.totalAmount,
            orderStatus: "string",
            customerName: "string",
            customerContact: "string",
            customerEmail: "string",
            receiverName: "string",
            notes: advisoryText,
            receiverContact: "string",
            baseAddress: "string",
            deliveryMessage: "string",
        };
    };

    const orderBusinessCard = async () => {
        try {
            let body;
            if (selectedCardType?.name === "일반명함") {
                body = findSpecAndCreateBody(
                    selectedDataGeneral,
                    selectedDataGeneral?.specs,
                );
            } else if (selectedCardType?.name === "고급명함") {
                body = findSpecAndCreateBody(
                    selectedDataPremium,
                    selectedDataPremium?.specs,
                );
            } else if (selectedCardType?.name === "커스텀명함") {
                body = {
                    userId: userId,
                    businessCardId: 1,
                    printOrderType: selectedCardType?.name,
                    cardMaterialType: "",
                    paperType: "",
                    printMethod: "",
                    coatingType: "",
                    size: "",
                    postProcessing: "",
                    quantity: 0,
                    totalPrice: 0,
                    orderStatus: "",
                    customerName: "",
                    customerContact: "",
                    customerEmail: "",
                    receiverName: "",
                    notes: advisoryText,
                    receiverContact: "",
                    baseAddress: "",
                    deliveryMessage: "",
                };
            }
            if (body && selectedCardType?.name === "커스텀명함") {
                const response =
                    await postBusinessCardsOrdersSprints(body).unwrap();
                console.log("Order successful:", response);
                navigate(`/card-sprint-request-completed/${response?.id}`);
            }

            if (body && selectedCardType?.name !== "커스텀명함") {
                const response =
                    await postBusinessCardsOrdersSprints(body).unwrap();
                console.log("Order successful:", response);

                if (response) {
                    try {
                        const responsePrint = await postOrderPrints({
                            userId: userData?.userID,
                            productId: response?.id,
                            itemName: "string",
                            quantity: 1,
                            totalAmount: 0,
                            orderStatus: "NOT_STARTED",
                        });

                        if (responsePrint.data) {
                            console.log(responsePrint?.data);
                            navigate(
                                `/card-sprint-pay/${responsePrint?.data?.order?.id}?extraId=${response?.id}`,
                            );
                        } else {
                            // toast.error("");
                        }
                    } catch (err) {
                        console.error("Error verifying OTP", err);
                    }
                }
            }
        } catch (error) {
            console.error("Failed to order business card:", error);
        }
    };

    return (
        <section className="flex flex-col max-md:px-5">
            <div className="mt-12 flex flex-col justify-center self-start leading-none max-md:mt-10">
                <h2 className="text-2xl font-bold text-main-color">STEP 2.</h2>
                <p className="mt-2.5 text-xl font-semibold text-neutral-700">
                    명함의 수량을 선택하세요!
                </p>
            </div>
            <div className="mt-8 flex items-start justify-evenly gap-5 rounded-md border border-solid border-gray-200 py-16 max-md:mx-auto max-md:mt-0 max-md:w-full max-md:max-w-[400px] max-md:flex-col max-md:border-none max-md:px-2">
                <div className="max-md:mx-auto max-md:mb-5 max-md:w-full">
                    <CardPreview />
                </div>
                {selectedCardType?.id === 1 && (
                    <div className="max-w-[60%] max-md:w-full max-md:max-w-[100%]">
                        <CardSpecifications
                            onSelectedDataChange={
                                handleSelectedDataChangeGeneral
                            }
                        />
                    </div>
                )}
                {selectedCardType?.id === 2 && (
                    <div className="max-w-[60%] max-md:w-full max-md:max-w-[100%]">
                        <CardSpecificationsTypeSecond
                            onSelectedDataChange={handleSelectedDataChange}
                        />
                    </div>
                )}
                {selectedCardType?.id === 3 && (
                    <div className="max-w-[60%] max-md:w-full max-md:max-w-[100%]">
                        <CardSpecificationTypeThird
                            advisoryText={advisoryText}
                            onTextChange={handleInputChange}
                        />
                    </div>
                )}
            </div>
            <Button
                onClick={orderBusinessCard}
                label={"바로 주문하기"}
                className="mb-32 mt-16 min-h-[50px] w-80 max-w-full gap-2.5 self-center rounded-md bg-stone-950 px-4 text-center text-base font-bold leading-none text-white max-md:mt-10"
            />
        </section>
    );
}
