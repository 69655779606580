import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { setStatusLoading } from "../../redux/featuresSlice/commonSlice";
import ImageList from "./ImageList";
import { usePostBusinessCardImagesToS3Mutation } from "../../services/apiSlice";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export default function TabsCreateLogo({
    value,
    setValue,
    onFileSelect,
    disableTab,
    onSelectId
}) {
    const dispatch = useDispatch(); 
    const theme = useTheme();
    const [fileName, setFileName] = React.useState("");
    const [postBusinessCardsImageToS3] =
        usePostBusinessCardImagesToS3Mutation();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const imagesName = file?.name;
        setFileName(imagesName);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    dispatch(setStatusLoading(true));

                    const imageType = file.type.split("/")[1];
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("imageType", imageType);

                    const response =
                        await postBusinessCardsImageToS3(formData).unwrap();

                    onFileSelect(response.filePath);
                } catch (error) {
                    console.error("Error uploading image:", error);
                } finally {
                    dispatch(setStatusLoading(false));
                }
            };
            reader.readAsDataURL(file);
        }
    };
    const handleSelectedId = (id) => {
        if (onSelectId) {
            onSelectId(id);
        }
    };
    return (
        <Box>
            <h3 className="text-base font-bold leading-none text-neutral-700">
                로고 파일 등록하기
            </h3>
            <AppBar position="static" className="mt-5 rounded-[10px]">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    textColor="inherit"
                    indicatorColor="unset"
                    className="rounded-[10px]"
                    sx={{
                        "& .MuiTab-root.Mui-selected": {
                            color: "white",
                            backgroundColor: "#3533FF",
                            borderRadius: "10px",
                        },
                        "& .MuiTab-root": {
                            padding: "5px",
                        },
                        color: "#4C4BC6",
                        bgcolor: "#F2F2FF",
                        padding: "10px",
                    }}
                >
                    <Tab
                        sx={{ minHeight: "36px", height: "36px" }}
                        disabled={disableTab}
                        label="내 제작물에서 로고 가져오기"
                        {...a11yProps(0)}
                    />
                    <Tab
                        sx={{ minHeight: "36px", height: "36px" }}
                        label="직접 파일 첨부하기"
                        {...a11yProps(1)}
                    />
                    <Tab
                        sx={{ minHeight: "36px", height: "36px" }}
                        label="로고없이 진행"
                        {...a11yProps(2)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                sx={{ padding: 0 }}
            >
                <div className="rounded-[10px] bg-[#F8F9FB] p-2">
                    <span className="font-bold">
                        로고로지에서 제작하여 JPG, PNG 로 구매한 로고를 불러오실
                        수 있습니다.{" "}
                    </span>
                    <ul className="ml-5 list-disc text-[#555555]">
                        <li>
                            단,{" "}
                            <span className="font-bold">
                                다운로드 가능 기간
                            </span>
                            까지만 가져오기 가능합니다.
                        </li>
                        <li>
                            <span className="font-bold">
                                벡터파일로 구매하시거나 로고로지 전문가를 통해
                                제작한 경우,
                            </span>{" "}
                            직접 파일 첨부하기로 진행해 주세요.
                        </li>
                        <li>
                            <span className="font-bold">
                                다운로드 기간이 지난 경우,
                            </span>{" "}
                            재구매하여 진행해 주세요.
                        </li>
                    </ul>
                </div>

                <ImageList onSelectId={handleSelectedId} />
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                sx={{ padding: 0 }}
            >
                <div className="rounded-[10px] bg-[#F8F9FB] p-2">
                    <span className="font-bold">
                        직접 로고파일을 첨부하거나, 전문가를 통해 받은 파일을
                        첨부해주세요.
                    </span>
                    <ul className="ml-5 list-disc text-[#555555]">
                        <li>
                            jpg, png 확장자의 이미지 파일만 업로드 가능합니다.{" "}
                        </li>
                        <li>최대 5MB 용량까지 업로드 가능합니다.</li>
                    </ul>
                </div>
                <div>
                    <div className="mt-10 flex w-full flex-col items-start leading-none max-md:max-w-full">
                        <div className="flex max-w-full flex-col">
                            <h3 className="text-base font-bold text-neutral-700">
                                파일첨부
                            </h3>
                        </div>
                        <div className="mt-4 flex w-[655px] max-w-full flex-wrap items-start gap-2.5">
                            <label
                                htmlFor="file-upload"
                                className="flex min-h-[50px] w-[150px] cursor-pointer items-center justify-center gap-2.5 rounded-md border border-solid border-indigo-600 text-base font-semibold text-indigo-600"
                            >
                                파일 선택
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                className="hidden"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                            <div className="flex min-h-[50px] w-[400px] min-w-[240px] items-center gap-3 self-stretch overflow-hidden rounded-md bg-stone-50 px-4 text-base font-medium text-stone-500">
                                {fileName ? (
                                    fileName
                                ) : (
                                    <span>선택된 파일이 없습니다.</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel
                value={value}
                index={2}
                dir={theme.direction}
                className="mt-4 rounded-[10px] bg-[#F8F9FB]"
            >
                로고없이 기본 템플릿으로만 명함을 제작하고 싶으신 경우, 로고
                없이 진행을 선택하여 작성해주세요.
            </TabPanel>
        </Box>
    );
}
TabsCreateLogo.propTypes = {
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
    onSelectId: PropTypes.func.isRequired,
};
