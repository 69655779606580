import React, { useState } from "react";
import FileListItem from "./FileListItem";
import { toast } from "react-toastify";

function FileAttachment({ files = [], onFilesChange }) {
    const [localFiles, setLocalFiles] = useState(files);
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const maxFileCount = 5;

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validFiles = [];
        let fileCount = localFiles.length;
    
        for (const file of selectedFiles) {
            if (file.size > maxFileSize) {
                toast.error(`File "${file.name}" 최대 용량인 5MB를 초과했습니다.`);
                continue;
            }
    
            if (fileCount >= maxFileCount) {
                toast.error("최대 5개의 파일만 업로드할 수 있습니다.");
                break;
            }
    
            validFiles.push(file);
            fileCount++;
        }
    
        const updatedFiles = [...localFiles, ...validFiles];
        setLocalFiles(updatedFiles);
    
        if (onFilesChange) {
            onFilesChange(updatedFiles);
        }
    
        event.target.value = "";
    };
    

    const handleRemoveFile = (index) => {
        const updatedFiles = localFiles.filter((_, i) => i !== index);
        setLocalFiles(updatedFiles);

        if (onFilesChange) {
            onFilesChange(updatedFiles);
        }
    };

    
    return (
        <div className="mt-10 flex w-full flex-col max-md:max-w-full">
            <div className="flex w-full flex-wrap items-center justify-between gap-10 whitespace-nowrap leading-none max-md:max-w-full">
                <div className="my-auto flex items-start gap-1 self-stretch">
                    <label
                        htmlFor="fileInput"
                        className="text-base font-bold text-neutral-700"
                    >
                        파일첨부
                    </label>
                    <span className="text-lg font-medium">*</span>
                </div>
                <div className="my-auto self-stretch text-right text-base text-neutral-500">
                    ({localFiles.length}/{maxFileCount})
                </div>
            </div>
            <div className="mt-2.5 flex w-full flex-col max-md:max-w-full">
                <label
                    htmlFor="fileInput"
                    className="min-h-[50px] w-full cursor-pointer gap-2.5 self-stretch rounded-md border border-solid border-indigo-600 px-5 py-4 text-base font-bold leading-none text-main-color max-md:max-w-full"
                >
                    파일 첨부하기
                </label>
                <input
                    type="file"
                    id="fileInput"
                    className="hidden"
                    multiple
                    onChange={handleFileChange}
                />

                <div className="mt-3.5 flex w-full flex-col max-md:max-w-full">
                    <div className="flex min-h-[220px] w-full flex-col items-start rounded-md border border-solid border-zinc-200 px-5 pb-48 pt-5 text-sm leading-none text-stone-500 max-md:max-w-full max-md:pb-24">
                        {localFiles.length > 0 ? (
                            localFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="mb-2 flex w-full items-center justify-between"
                                >
                                    <FileListItem
                                        fileName={file.name}
                                        removeFile={() =>
                                            handleRemoveFile(index)
                                        }
                                    />
                                </div>
                            ))
                        ) : (
                            <span>
                               첨부된 파일이 없습니다. 
                            </span>
                        )}
                    </div>
                    <div className="mt-2.5 flex items-center gap-1 self-start text-xs text-neutral-600">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/5683ff6f9e21a764d0b13f79a71a81ee0a7243b81f7c353b24b4f0faea55952d?apiKey=608912cecb5144cf887238b12afdb74b&"
                            alt=""
                            className="my-auto aspect-square w-[15px] shrink-0 self-stretch object-contain"
                        />
                        <div className="my-auto self-stretch">
                            최대 5MB 용량 업로드 가능
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileAttachment;
