import React from "react";

function InquiryAnswer({ answerTextLines, fileSrc, fileAlt, date }) {
  const isImage = (src) => {
    const imageExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = src?.split(".").pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  const isPdf = (src) => {
    const pdfExtension = "pdf";
    const fileExtension = src?.split(".").pop().toLowerCase();
    return fileExtension === pdfExtension;
  };

  // Hàm loại bỏ thẻ HTML
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <article className="flex flex-col pt-11 max-w-[900px]">
      <h2 className="w-full text-xl font-semibold leading-none whitespace-nowrap text-zinc-800 max-md:max-w-full">
        답변
      </h2>
      <section className="flex flex-col px-6 py-8 mt-5 w-full text-base rounded-md bg-slate-100 max-md:px-5 max-md:max-w-full">
        <p className="w-full font-medium leading-6 text-zinc-800 max-md:max-w-full">
          {answerTextLines.map((line, index) => (
            <React.Fragment key={index}>
              {stripHtmlTags(line)}
              <br />
            </React.Fragment>
          ))}
        </p>

        {fileSrc && isImage(fileSrc) ? (
          // Hiển thị hình ảnh
         <div className="flex justify-start">
           <img
            loading="lazy"
            src={fileSrc}
            className="object-cover mt-8 max-w-full aspect-[4.44] w-full h-full"
            alt={fileAlt}
          />
         </div>
        ) : fileSrc && isPdf(fileSrc) ? (
          // Hiển thị thẻ <a> cho file PDF
          <a
            href={fileSrc}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-8 text-blue-600 underline"
          >
           PDF 보기
          </a>
        ) : null}

        <time className="mt-8 leading-none text-neutral-700">{date}</time>
      </section>
    </article>
  );
}

export default InquiryAnswer;
