import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import images from "./../../../images";
import Button from "../../../components/common/Button/Button";
import {
    useGetBusinessCardsByIdQuery,
    usePostBusinessCardsOrdersMutation,
} from "../../../services/apiSlice";
import { toast } from "react-toastify";
import ModalComponent from "../../../components/common/Modals/ModalComponent";

function CheckApplyBusinessCard() {
    const { id } = useParams();
    const navigate = useNavigate();
    const cardId = id;
    const { data, error, isLoading } = useGetBusinessCardsByIdQuery({ cardId });
    const [postOrders] = usePostBusinessCardsOrdersMutation();
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const initModal = {
        status: false,
        type: "",
        title: "",
        content: "",
    };
    const [modal, setModal] = useState(initModal);
    const [modalCreateCardAgain, setModalCreateCardAgain] = useState(false);

    const handleCreateOrders = async () => {
        if (userId) {
            try {
                const response = await postOrders({
                    userId: userId,
                    productId: data?.id,
                    itemName: "string",
                    quantity: 1,
                    totalAmount: 0,
                    orderStatus: "PENDING",
                });
                if (response) {
                    setModal(false);
                    navigate(`/card/order/${cardId}`, {});
                }
            } catch (error) {
                setModal(false);
                console.error(error);
            }
        } else {
            setModal({
                ...modal,
                type: "verify",
                status: true,
                title: "가입완료",
                content:
                    "명함을 다시 만드시려는 경우,\n현재 시안이 초기화됩니다.\n명함 타입 선택부터\n다시 새롭게 진행하시겠어요?",
            });
        }
    };

    const handleConfirmOrder = async () => {
        navigate("/login");
    };

    const handleCloseModal = () => {
        setModal({
            ...modal,
            status: false,
        });
    };

    const handleBack = () => {
        // navigate(`/card/create/${cardId}`);
        window.history.back(); // Quay lại trang trước đó
    };

    const handleAgain = () => {
        // navigate(`/`);
        setModalCreateCardAgain(true)
    };

    const handleConfirmCreateAgain=()=>{
          navigate(`/card`);
    }

    const handleCloseModalCreateAgain =()=>{
        setModalCreateCardAgain(false)
    }
    return (
        <main className="flex flex-col items-center overflow-hidden bg-white max-md:px-5">
            <img
                loading="lazy"
                src={images.businessCardIcon}
                alt="Business Card Icon"
                className="mt-20 aspect-square w-[50px] object-contain max-md:mt-10"
            />
            <h1 className="mt-6 text-center text-3xl font-bold leading-none text-main-color">
                명함 시안 확인
            </h1>
            <p className="mt-3.5 text-center text-2xl leading-none text-zinc-800">
                입력하신 정보를 확인해보세요.
            </p>
            <section className="flex flex-col items-center max-md:px-5">
                <img
                    loading="lazy"
                    src={data?.filePathFront}
                    alt="Business Card Front Preview"
                    className="mt-10 aspect-[1.8] w-full max-w-[531px] object-contain max-md:px-10"
                />

                <img
                    loading="lazy"
                    src={data?.filePathBack}
                    alt="Business Card Back Preview"
                    className="mt-5 aspect-[1.8] w-full max-w-[531px] object-contain max-md:px-10"
                />
                <div className="mt-10 flex w-[468px] max-w-full items-start gap-2.5 text-center text-base font-bold leading-none max-md:px-10">
                    <Button
                        label={"정보 다시 입력"}
                        onClick={handleBack}
                        className="min-h-[50px] flex-1 shrink gap-2.5 self-stretch rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                    />
                    <Button
                        label={"선택한 시안으로 구매"}
                        className="min-h-[50px] flex-1 shrink gap-2.5 self-stretch rounded-md bg-indigo-600 px-4 text-white"
                        onClick={handleCreateOrders}
                    />
                </div>
                <Button
                    label={"다시 만들기"}
                    className="mb-20 mt-11 text-center text-base font-bold leading-none text-zinc-800 max-md:my-10"
                    onClick={handleAgain}
                />
            </section>

            <ModalComponent
                title={modal.title}
                titleClass="flex justify-center"
                open={modal.status}
                content={modal.content}
                contentClass="text-center"
                buttonClassContainer="flex flex-row-reverse"
                buttonClose={{
                    buttonClass:
                        "bg-white text-black px-4 py-2 rounded border border-[##E8E8E8] min-w-[165px]",
                    label: "취소",
                    onClose: () => {
                        handleCloseModal();
                    },
                }}
                buttonSubmit={{
                    buttonClass:
                        "bg-[#3533FF] text-white px-4 py-2 rounded min-w-[165px]",
                    label: "다시 만들기",
                    onSubmit: () => {
                        handleConfirmOrder();
                    },
                }}
            />

            <ModalComponent
                title={'알림'}
                titleClass="flex justify-center"
                open={modalCreateCardAgain}
                content={"명함을 다시 만드시려는 경우,\n현재 시안이 초기화됩니다.\n명함 타입 선택부터\n다시 새롭게 진행하시겠어요?"}
                contentClass="text-center"
                buttonClassContainer="flex flex-row-reverse"
                buttonClose={{
                    buttonClass:
                        "bg-white text-black px-4 py-2 rounded border border-[##E8E8E8] min-w-[165px]",
                    label: "취소",
                    onClose: () => {
                        handleCloseModalCreateAgain();
                    },
                }}
                buttonSubmit={{
                    buttonClass:
                        "bg-[#3533FF] text-white px-4 py-2 rounded min-w-[165px]",
                    label: "다시 만들기",
                    onSubmit: () => {
                        handleConfirmCreateAgain();
                    },
                }}
            />
        </main>
    );
}

export default CheckApplyBusinessCard;
