import React from "react";
const CardSpecificationTypeThird = ({ advisoryText, onTextChange }) => {
  const handleAdvisoryTextChange = (event) => {
    onTextChange(event.target.value); 
  };
  return (
    <article className="flex flex-col min-w-[240px] max-w-[600px] max-md:max-w-full">
      <header className="flex flex-col w-full max-md:max-w-full">
        <h1 className="text-2xl font-bold leading-none text-main-color max-md:max-w-full">
          커스텀명함
        </h1>
        <p className="mt-2.5 text-base leading-6 text-neutral-700 max-md:max-w-full">
          나만의 개성과 브랜드를 표현하기 위해 독특한 디자인, 색상, 재질
          <br />
          그리고 특수 인쇄 기법 등 자유롭게 선택할 수 있습니다.
        </p>
      </header>
      <section className="flex flex-col mt-8 w-full max-md:max-w-full">
        <div className="flex w-full bg-gray-200 rounded-md min-h-[1px] max-md:max-w-full" />
        <div className="flex flex-col py-9 w-full rounded max-md:max-w-full">
          <label
            htmlFor="consultationRequest"
            className="text-base font-bold leading-none text-neutral-700"
          >
            전문가 상담 요청
          </label>
          <textarea
            id="consultationRequest"
            value={advisoryText} 
            onChange={handleAdvisoryTextChange} 
            className="gap-2.5 px-5 pt-5 pb-14 mt-5 w-full text-base leading-6 rounded-md border border-solid bg-stone-50 border-stone-50 min-h-[320px] text-zinc-500 max-md:max-w-full"
            placeholder="커스텀명함 제작 시 전문가 상담이 필요합니다.
제작을 원하시는 사항을 입력해주시면 확인 후 상담이 진행됩니다.
아래 예시문을 확인 후 요청 사항을 작성해주세요.

(요청 작성 예시)
종이는 광이 하나도 없고, 두꺼웠으면 좋겠습니다.
글자는 금색으로 반짝 거리게 표현하고 명함 종이의 테두리는
원하는 모양으로 잘라져 있으면 좋겠어요

메일주소 : OOOOO@mail.com
연락처 : 010 - OOOO - OOOO"
            aria-label="전문가 상담 요청 입력란"
          />
        </div>
        <section className="flex flex-wrap gap-10 justify-between items-center px-5 w-full text-lg font-bold leading-none rounded-md bg-stone-50 min-h-[60px] max-md:max-w-full">
          <h2 className="self-stretch my-auto text-zinc-800">총 결제 금액</h2>
          <p className="self-stretch my-auto text-right text-main-color">
            상담 후 결정
          </p>
        </section>
      </section>
    </article>
  );
};

export default CardSpecificationTypeThird;
