import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import ImageGrid from "./ImageGrid";
import PageTitle from "./PageTitle";
import TabNavigation from "./TabNavigation";
import ImageComponent from "../../components/FooterBanner";
import { useGetBusinessCardsImagesQuery } from "../../services/apiSlice";
import PaginationComponent from "../../components/common/Pagination/PaginationComponent";

const ViewPage = () => {
    const [activeTab, setActiveTab] = useState("logo");
    const [currentPage, setCurrentPage] = useState(1);

    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [images, setImages] = useState({
        images: { logo: null, businessCard: null },
    });

    const imagesPerPage = 16;
    const order = "DESC";
    const imageType = activeTab === "logo" ? "logo" : "business-card";

    const {
        data: fetchedImages,
        isLoading,
        error,
        refetch
    } = useGetBusinessCardsImagesQuery({
        order,
        page: currentPage,
        take: imagesPerPage,
        imageType,
    });

    const currentImages = fetchedImages?.data || [];
    const totalPages = fetchedImages ? fetchedImages?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const logoImageFooter = getImageByType("logo-footer");
            const businessCardFooter = getImageByType("business-card-footer");

            setImages((prevState) => ({
                images: {
                    logo: logoImageFooter[0]?.filePath || null,
                    businessCard: businessCardFooter[0]?.filePath || null,
                },
            }));
        }
    }, [imageTypesData]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
    };

    useEffect(() => {
        refetch()
    }, []);

    return (
        <div className="flex min-h-screen flex-col items-center justify-center overflow-hidden bg-white">
            <main className="flex w-full flex-col items-center">
                <PageTitle
                    title="둘러보기"
                    description="로고로지에서 제작한 다양한 로고디자인과 명함디자인을 둘러보세요."
                />
                <TabNavigation
                    activeTab={activeTab}
                    setActiveTab={handleTabChange}
                />
                <div className="mt-10 flex w-full flex-col items-center self-center max-md:max-w-full">
                    <ImageGrid images={currentImages} />
                </div>

                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </main>
            <div className="mt-24">
                <ImageComponent
                    src={
                        activeTab === "logo"
                            ? images.images.logo
                            : images.images.businessCard
                    }
                />
            </div>
        </div>
    );
};

export default ViewPage;
