import React, { useState, useEffect } from "react";
import TableCommon from "../../../components/common/CommonTable/TableCommon";
import Button from "../../../components/common/Button/Button";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import { useGetListHistoryOrderLogoMutation } from "../../../services/apiSlice";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../utils/helper/formatters";
import { addDays } from "../../../utils/helper/date.helper";
import { OrderLogoStatus } from "../../../utils/constants/enum/logoStatusEnum";

const MyLogoProductList = () => {
    const [page, setPage] = useState(1);
    const { userData, accessToken } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const columns = [
        { label: "작업물 이미지", field: "image" },
        { label: "작업물 정보", field: "date" },
        { label: "확장자", field: "fileType" },
        { label: "다운로드", field: "downloadPeriod" },
        { label: "상태", field: "status" },
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case "취소":
                return "#6b7280";
            case "진행중":
                return "#4f46e5";
            case "접수확인중":
                return "#dc2626";
            case "완료":
                return "#1f2937";
            default:
                return "#1f2937";
        }
    };

    const [getListOrderLogo, { data }] = useGetListHistoryOrderLogoMutation();
    useEffect(() => {
        const fetchLogosList = async () => {
            await getListOrderLogo({
                page: page,
                userId: userId,
            });
        };
        fetchLogosList();
    }, [getListOrderLogo, page]);

    const totalPages = data ? data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <>
            <div className="w-full pb-10">
                <TableCommon
                    columns={columns}
                    data={(data?.data || []).map((item) => ({
                        ...item,
                        image: (
                            <div>
                                <img
                                    src={item?.logo?.filePath}
                                    alt=""
                                    style={{ width: 148 }}
                                />
                            </div>
                        ),
                        date: (
                            <>
                                <div className="flex flex-col">
                                    <span>
                                        {item?.logo?.businessName ?? ""}
                                    </span>
                                    <div className="flex flex-row">
                                        <div className="min-w-24">구매일</div>{" "}
                                        <div>
                                            {formatDateTime(
                                                item?.logo?.createdAt,
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="min-w-24">
                                            다운로드 기간
                                        </div>{" "}
                                        <div>
                                            {formatDateTime(
                                                item?.logo?.createdAt,
                                            )}

                                            {item?.logo?.createdAt && (
                                                <>
                                                    ~{" "}
                                                    {formatDateTime(
                                                        addDays(
                                                            item?.logo
                                                                ?.createdAt,
                                                            3,
                                                        ),
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ),

                        fileType: (
                            <>
                                <span>{item?.order?.receivedOrderType}</span>
                            </>
                        ),

                        downloadPeriod: (
                            <>
                                {new Date() >
                                addDays(item?.logo?.createdAt, 3) ? (
                                    <Button
                                        label={"재구매하기"}
                                        className={`my-auto min-w-[110px] gap-2.5 self-stretch rounded border border-indigo-600 bg-white px-3 py-2.5 font-medium text-indigo-600`}
                                    />
                                ) : (
                                  <div className={`my-auto min-w-[110px] gap-2.5 self-stretch text-center rounded bg-[#E8E8FF] px-3 py-2.5 font-medium text-indigo-600`}>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={item?.logo?.filePath}
                                        
                                        href={item?.logo?.filePath}
                                    >
                                        다운로드
                                    </a>
                                  </div>
                                  
                                )}
                            </>
                        ),
                        status: (
                            <span
                                style={{
                                    color: getStatusColor(
                                        OrderLogoStatus[
                                            item?.order?.orderStatus
                                        ],
                                    ),
                                }}
                            >
                                {OrderLogoStatus[item?.order?.orderStatus]}
                            </span>
                        ),
                    }))}
                />
            </div>
            <div className="mb-24 flex justify-center">
                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </div>
        </>
    );
};

export default MyLogoProductList;
