import React, { useState, useEffect } from "react";
import FileUpload from "./FileUpload";
import InputField from "./../../../../components/common/Input/InputField";
import TextArea from "./../../../../components/common/Input/TextArea";
import Button from "../../../../components/common/Button/Button";
import { usePostUserQueryMutation } from "../../../../services/apiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setStatusLoading } from "./../../../../redux/featuresSlice/commonSlice"; 

function InquiryForm({statusSuccess}) {
    const navigate = useNavigate();
    const dispatch = useDispatch(); 

    const { userData } = useSelector((state) => state.common);

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);
    const [postUserQuery] = usePostUserQueryMutation();


    const handleFileUpload = ({ binaryData, fileName, fileType }) => {
        setFile({ binaryData, fileName, fileType });
    };

    console.log("file", file);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        const validatedTitle = title?.toString().trim();
        const validStatus = ["COMPLETED", "IN_COMPLETED"];
        const status = "IN_COMPLETED";

        if (!userData?.userID) {
            console.error("User ID is required");
            return;
        }

        if (!validatedTitle) {
            console.error("Title is required");
            return;
        }

        if (!validStatus.includes(status)) {
            console.error("Invalid status value");
            return;
        }

        formData.append("userId", userData.userID);
        formData.append("question", validatedTitle);
        formData.append("content", content?.toString().trim() || "");
        formData.append("status", status);

        if (file) {
            const blob = new Blob([file.binaryData], { type: file.fileType });
            formData.append("image", blob, file.fileName);
        }

        try {
            dispatch(setStatusLoading(true));

            const responsePostUserQuery = await postUserQuery(formData);
            console.log("Response:", responsePostUserQuery);

            if (responsePostUserQuery) {
                dispatch(setStatusLoading(false));
                toast.success("신청이 완료되었습니다.");
                setTitle("");
                setContent("");
                setFile(null);
                statusSuccess(true);
            }
        } catch (err) {
            toast.error("신청이 실패했습니다.");
            console.error("Error posting expert request", err);
        }
    };

    return (
        <main className="flex flex-col rounded-none py-10">
            <header className="flex flex-col justify-center self-start leading-none">
                <h1 className="text-2xl font-bold text-indigo-600">문의하기</h1>
                <h2 className="mt-2.5 text-xl font-semibold text-neutral-700">
                    1:1 문의하실 사항을 작성해주세요.
                </h2>
            </header>

            <form className="mt-5 flex w-full flex-col border border-solid border-neutral-200 bg-white px-12 py-10 max-md:max-w-full max-md:px-5">
                <InputField
                    label="제목"
                    placeholder="제목을 입력해주세요."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} // Cập nhật state
                />
                <TextArea
                    label="상세 내용"
                    placeholder="문의하실 내용을 입력해주세요."
                    value={content}
                    onChange={(e) => setContent(e.target.value)} // Cập nhật state
                />
                <FileUpload
                    onFileUpload={handleFileUpload}
                    fileName={file?.fileName}
                />{" "}
                {/* Xử lý upload file */}
            </form>
            <Button
                onClick={handleSubmit}
                label={" 문의 보내기"}
                className="mb-10 mt-10 w-80 max-w-full gap-2.5 self-center rounded-md bg-indigo-600 px-4 py-4 text-center text-base font-bold leading-none text-white"
            />
        </main>
    );
}

export default InquiryForm;
