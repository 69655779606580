import * as React from "react";
// import images from "../../../../images";

function NoticationDetailContent({content,images}) {
  return (
    <main className="flex flex-col px-10 py-11 w-full text-base font-medium leading-6 text-zinc-800 max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src={images}
        className="object-contain max-w-full aspect-[1.11] w-[616px]"
        alt="Notice content illustration"
      />
      <section className="mt-8 w-full max-md:max-w-full">
      {typeof content === "string" ? content : JSON.stringify(content)}
      </section>
    </main>
  );
}

export default NoticationDetailContent;
