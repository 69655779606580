import React, { useState,useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ProductImage from "../../BusinessCard/MakeBusinessCard/ProductImage";
import PaymentMethod from "../../../components/common/PaymentMethod/PaymentMethod";
import TotalPayment from "../../../components/common/PaymentMethod/TotalPayment";
import InfoSection from "../../../components/common/InfoSection/InfoSection";
import FormInput from "../../../components/common/FormInput/FormInput";
import ShippingForm from "./ShippingForm";
import {
    useGetBusinessCardOrderPrintByIdQuery,
    usePostKakaoInitializeMutation,
    usePostOrdersMutation,
    usePostNaverPayInitializeMutation,
    usePostTossPaymentInitializeMutation,
} from "../../../services/apiSlice";
import { toast } from "react-toastify"; // Import toast
import { useSelector } from "react-redux";
import {
    formatPrice,
    formatPriceString,
} from "../../../utils/helper/formatters";
import { getFieldValueByName } from "../../../utils/helper/function";
import PaymentMethodEnum from "../../../utils/constants/enum/paymentMethodEnum";

const CardSprintPay = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const extraId = queryParams.get("extraId");

    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const { id } = useParams();
    const navigate = useNavigate();

    const [postKakaoInitialize, { data: dataKakao }] =
        usePostKakaoInitializeMutation();

    const [postTossPaymentInitialize, { data: dataTossPayment }] =
        usePostTossPaymentInitializeMutation();

    const [postNaverPayInitialize, { data: dataNaverPay }] =
        usePostNaverPayInitializeMutation();
    const businessCardsPrintOrderId = id;
    const {
        data: businessCardPrintsOrder,
        isLoading,
        isError,
    } = useGetBusinessCardOrderPrintByIdQuery({
        businessCardsOrderPrintsId: extraId,
    });

    console.log(
        "businessCardPrintsOrderbusinessCardPrintsOrder",
        businessCardPrintsOrder,
    );

    const infoItems = [
        {
            label: "인쇄타입",
            value: businessCardPrintsOrder?.order?.printOrderType,
        },
        { label: "용지", value: businessCardPrintsOrder?.order?.paperType },
        {
            label: "인쇄도수",
            value: businessCardPrintsOrder?.order?.printMethod,
        },
        { label: "코팅", value: businessCardPrintsOrder?.order?.coatingType },
        { label: "규격", value: businessCardPrintsOrder?.order?.size },
        {
            label: "후가공",
            value: businessCardPrintsOrder?.order?.postProcessing,
        },
        {
            label: "수량",
            value: `${businessCardPrintsOrder?.order?.quantity}매`,
        },
        {
            label: "결제금액",
            value: formatPriceString(
                businessCardPrintsOrder?.order?.totalPrice,
            ),
        },
    ];
    const [formFields, setFormFields] = useState([
        {
            name: "name",
            label: "이름",
            placeholder: "주문자 이름을 입력해주세요.",
            value: "",
        },
        {
            name: "contact",
            label: "연락처",
            placeholder: "연락처를 입력해주세요.",
            value: "",
        },
        {
            name: "email",
            label: "이메일",
            placeholder: "이메일을 입력해주세요.",
            value: "",
        },
    ]);

    const [shippingData, setShippingData] = useState({
        recipientName: "",
        contact: "",
        zipCode: "",
        addressDefault: "",
        addressRemaining: "",
        shippingMessage: "",
    });

    const handleInputChange = (fieldName, newValue) => {
        const updatedFields = formFields.map((field) =>
            field.name === fieldName ? { ...field, value: newValue } : field,
        );
        setFormFields(updatedFields);
        console.log();
    };

    const handleShippingDataChange = (data) => {
        setShippingData(data);
    };

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

    const handlePaymentSelect = (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod);
    };

    const [postOrders, { loading, error, data }] = usePostOrdersMutation();
    
    const handleBuyClick = async () => {
        if (!selectedPaymentMethod?.name) {
            toast.error("결제수단을 선택해주세요");
            return;
        }

        const body = {
            orderId: businessCardsPrintOrderId,
            userId,
            amount: parseFloat(businessCardPrintsOrder?.order?.totalPrice),
            paymentMethod: "",
            paymentStatus: "PENDING",
            receivedOrderType: "PNG",
            orderDetails: {
                customerName: getFieldValueByName(formFields, "name"),
                customerContact: getFieldValueByName(formFields, "contact"),
                customerEmail: getFieldValueByName(formFields, "email"),

                receiverName: shippingData?.recipientName,
                receiverContact: shippingData?.contact,
                baseAddress: shippingData?.addressDefault,
                deliveryMessage: shippingData?.shippingMessage,
                zipCode: shippingData?.zipCode,
                detailAddress: shippingData?.addressRemaining,
            },
        };

        const paymentActions = {
            [PaymentMethodEnum.NAVER_PAY]: async () => {
                body.paymentMethod = "NAVER_PAY";
                const resNaver = await postNaverPayInitialize(body);
                console.log("resNaverresNaver",resNaver);
                
                if (!window.oPay && resNaver?.data.id) {
                    alert("Naver Pay SDK is not loaded yet.");
                    return;
                }
                if (resNaver?.data.id) {
                    window.oPay.open({
                        merchantPayKey: "6931022d-b25b-4233-89d9-9188c434aa2f",
                        productName: "Card",
                        productCount: "1",
                        totalPayAmount: body.amount,
                        taxScopeAmount: body.amount,
                        taxExScopeAmount: "0",
                        returnUrl: `${process.env.REACT_APP_BASE_URL}/payments/naver-pay/callback?orderId=${body.orderId}`,
                    });
                }
            },
            [PaymentMethodEnum.REAL_TIME_ACCOUNT_TRANSFER]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_CARD";
                body.tossPaymentMethod = "CARD";
                const resTossCard = await postTossPaymentInitialize(body);
                const url = resTossCard?.data?.url;

                if (url) {
                    window.location.href = url;
                } else {
                    console.error("No URL returned from Toss Payment.");
                }
            },
            [PaymentMethodEnum.GENERAL_CARD_PAYMENT]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_TRANSFER";
                body.tossPaymentMethod = "TRANSFER";
                const resTossTransfer = await postTossPaymentInitialize(body);
                const urlTransfer = resTossTransfer?.data?.url;

                if (urlTransfer) {
                    window.location.href = urlTransfer;
                } else {
                    console.error(
                        "No URL returned from Toss Payment Transfer.",
                    );
                }
            },
            [PaymentMethodEnum.KAKAO_PAY]: async () => {
                body.paymentMethod = "KAKAO_PAY";
                const resKakao = await postKakaoInitialize(body);
                const urlKakao = resKakao?.data?.next_redirect_pc_url;

                if (urlKakao) {
                    window.location.href = urlKakao;
                } else {
                    console.error("No URL returned from Kakao Pay.");
                }
            },
        };

        const action = paymentActions[selectedPaymentMethod.name];
        if (action) {
            await action();
        } else {
            console.error("Invalid payment method selected.");
        }
    };

    useEffect(() => {
            const script = document.createElement("script");
            script.src = "https://nsp.pay.naver.com/sdk/js/naverpay.min.js";
            script.async = true;
    
            script.onload = () => {
                window.oPay = window.Naver.Pay.create({
                    mode: "development",
                    clientId: "HN3GGCMDdTgGUfl0kFCo",
                    chainId: "UHJBaHJXTk4rZDd",
                });
            };
    
            document.body.appendChild(script);
    
         
            return () => {
                document.body.removeChild(script);
            };
        }, []);
    return (
        <main className="rounded-none px-20 py-10 max-md:px-5">
            <div className="flex gap-5 max-md:flex-col">
                <section className="flex w-[63%] flex-col max-md:ml-0 max-md:w-full">
                    <div className="flex grow flex-col max-md:mt-10 max-md:max-w-full">
                        <div className="flex w-full flex-col max-md:max-w-full">
                            <h2 className="w-full border-b-2 border-neutral-800 pb-4 text-2xl font-bold leading-none text-zinc-800 max-md:max-w-full">
                                구매 상품 정보
                            </h2>
                            <ProductImage
                                imageBussinessCardFront={
                                    businessCardPrintsOrder?.businessCard
                                        ?.filePathFront
                                }
                                imageBussinessCardBack={
                                    businessCardPrintsOrder?.businessCard
                                        ?.filePathBack
                                }
                            />
                        </div>
                        <InfoSection
                            title="인쇄 주문 정보"
                            infoItems={infoItems}
                        />

                        <div className="mt-24 flex w-full flex-col justify-center leading-none text-neutral-700 max-md:mt-10 max-md:max-w-full">
                            <h3 className="flex w-full flex-col text-2xl font-bold max-md:max-w-full">
                                <div className="gap-2.5 self-start">
                                    주문자 정보
                                </div>
                                <div className="mt-4 flex min-h-[2px] w-full bg-neutral-600 max-md:max-w-full" />
                            </h3>
                            {/* <FormInput formFields={oderFormFields} /> */}
                            <FormInput
                                formFields={formFields}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mt-24 flex w-full flex-col justify-center leading-none text-neutral-700 max-md:mt-10 max-md:max-w-full">
                            <h3 className="flex w-full flex-col text-2xl font-bold max-md:max-w-full">
                                <div className="gap-2.5 self-start">
                                    배송지 정보
                                </div>
                                <div className="mt-4 flex min-h-[2px] w-full bg-neutral-600 max-md:max-w-full" />
                            </h3>
                            <ShippingForm
                                onShippingDataChange={handleShippingDataChange}
                            />
                        </div>
                        <PaymentMethod onPaymentSelect={handlePaymentSelect} />
                        <TotalPayment
                            amount={parseFloat(
                                businessCardPrintsOrder?.order?.totalPrice,
                            )}
                        />
                    </div>
                </section>

                <aside className="flex w-[37%] flex-col justify-between max-md:ml-0 max-md:w-full">
                    <div className="sticky top-0 mt-14 flex w-full flex-col max-md:mt-10 max-md:max-w-full">
                        <div className="flex flex-col rounded-xl border border-solid border-gray-200 bg-white px-8 py-9 max-md:max-w-full max-md:px-5">
                            <div className="flex w-full flex-col items-start">
                                <div className="flex w-full flex-col self-stretch rounded-xl bg-slate-50 px-5 py-6">
                                    <h3 className="text-base font-bold leading-none text-neutral-700">
                                        [상품 구매 시 안내사항]
                                    </h3>
                                    <p className="mt-4 w-full text-sm leading-5 text-zinc-800">
                                        이용약관 및 주문 내용을 확인하였으며,
                                        결제 진행을 위해 개인정보 수집 및 이용에
                                        동의합니다.
                                    </p>
                                </div>

                                <div className="mt-4 flex w-full flex-col gap-4">
                                    <label className="flex cursor-pointer items-center gap-2.5 text-base text-neutral-700">
                                        <div>
                                            {" "}
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4"
                                            />
                                        </div>
                                        주문 내용을 모두 확인했으며, 상품 결제
                                        동의합니다.
                                    </label>
                                    <label className="flex cursor-pointer items-center gap-2.5 text-base text-neutral-700">
                                        <div>
                                            {" "}
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4"
                                            />
                                        </div>
                                        개인정보 수집 및 이용에 동의합니다.
                                    </label>
                                </div>
                            </div>

                            <div className="mt-9 flex w-full flex-col justify-center text-center text-base font-bold leading-none">
                                <button
                                    type="submit"
                                    className="min-h-[50px] w-full rounded-md bg-indigo-600 px-4 text-white"
                                    onClick={handleBuyClick}
                                >
                                    구매하기
                                </button>
                                <button
                                    type="button"
                                    className="mt-4 min-h-[50px] w-full rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                                >
                                    이전으로
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mb-40 ml-5 gap-2.5 self-start bg-black p-8 text-2xl font-bold leading-9 text-white max-md:ml-2.5 max-md:mt-10 max-md:px-5">
                        ✅ 결제 수단 <br />
                        <span className="text-xl">
                            개발 시 토스페이먼츠 가이드대로{" "}
                        </span>
                        <br />
                        <span className="text-xl leading-7">
                            디자인 변경하여 진행 필요
                        </span>
                    </div>
                </aside>
            </div>
        </main>
    );
};

export default CardSprintPay;
