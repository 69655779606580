import React, { useState, useEffect } from "react";
import OrderCard from "./OrderCard";
import images from "../../../images";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import { useGetListHistoryOrderPrintBusinessCardMutation } from "../../../services/apiSlice";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../utils/helper/formatters";
import { statusPrintCardOrder } from "../../../utils/constants/enum/printCardOrderStatus";
import { useNavigate } from "react-router-dom";

const PrintOrderHistory = () => {
      const navigate = useNavigate();
  
    const { userData, accessToken } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const [page, setPage] = useState(1);

    const [getListOrderPrintBusinessCard, { data }] =
        useGetListHistoryOrderPrintBusinessCardMutation();
    useEffect(() => {
        const fetchLogosList = async () => {
            await getListOrderPrintBusinessCard({
                page: page,
                userId: userId,
            });
        };
        fetchLogosList();
    }, [getListOrderPrintBusinessCard, page]);

    console.log("data", data);

    const totalPages = data ? data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };
    const handleToDetails =(order)=>{
      console.log("oder", order);
      navigate(`/order-history-details/${order?.print?.id}`);
    }
    return (
        <main className="pb-20">
            <div className="mt-8 w-full max-w-[1240px] max-md:max-w-full">
                <div className="mx-auto flex flex-row flex-wrap justify-center gap-5 max-md:flex-col">
                    {(data?.data || []).map((order, index) => (
                        <div
                            key={index}
                            className="flex flex-col max-md:ml-0 max-md:w-full"
                        >
                            <OrderCard
                              handleToDetails={() =>handleToDetails(order)}
                                orderDate={formatDateTime(
                                    order?.order?.createdAt,
                                )}
                                imageUrl1={order?.print?.filePathFront}
                                imageUrl2={order?.print?.filePathBack}
                                trackingInfoCourier={
                                  order?.print?.shippingStatus === 'ON_DELIVERY' 
                                  ? order?.print?.shippingCompany ?? "_" 
                                  : statusPrintCardOrder[order?.print?.shippingStatus] ?? "_"
                                }
                                trackingInfoTrackingNumber={
                                  order?.print?.shippingStatus === 'ON_DELIVERY' 
                                  ? order?.print?.trackingNumber ?? ""
                                  : ""
                                    
                                }
                                recipientInfoName={
                                    order?.print?.customerName
                                        ? order?.print?.customerName
                                        : "_"
                                }
                                recipientInfoAddress={`${order?.print?.baseAddress ? order?.print?.baseAddress : "_"} ${order?.print?.detailAddress }`}
                                recipientInfoPhone={
                                    order?.print?.customerContact
                                        ? order?.print?.customerContact
                                        : "_"
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-24 mt-12 flex justify-center">
                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </div>
        </main>
    );
};

export default PrintOrderHistory;
