import React, { useState } from "react";
import Button from "../../components/common/Button/Button";
import { Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../utils/helper/function";
import StepHeader from "../LayoutCommon/StepHeader";
import TabPanel from "../../components/common/Tabs";
import { useTheme } from "@emotion/react";
import ConsultationDetails from "./ConsultationDetails";
import Step1Form from "./Step1Form";
import ConsultationDetailsHireAndExpert from "./ConsultationDetailsHireAndExpert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    usePostExpertRequestsMutation,
    usePostBusinessCardImagesToS3Mutation,
} from "../../services/apiSlice";
import { setStatusLoading } from "../../redux/featuresSlice/commonSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function ExpertConsultationForm() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    const { userData } = useSelector((state) => state.common);
    const [formData, setFormData] = useState({
        companyName: "",
        email: "",
        contactName: "",
        phone: "",
        selectedCardType: {},
        selectedFiles: [],
        requestDetails: "",
    });
    const [postExpertRequest] = usePostExpertRequestsMutation();
    const [postBusinessCardsImageToS3] =
        usePostBusinessCardImagesToS3Mutation();

    const handleFilesChange = async (files) => {
        const uploadedFiles = [];

        for (const file of files) {
            try {
                dispatch(setStatusLoading(true));

                const imageType = file.type.split("/")[1];

                const formData = new FormData();
                formData.append("file", file);
                formData.append("imageType", "DOWNLOAD");

                const response =
                    await postBusinessCardsImageToS3(formData).unwrap();

                uploadedFiles.push({
                    url: response?.filePath,
                    id: response?.id,
                    name: file.name,
                });
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        }

        setFormData((prevState) => ({
            ...prevState,
            selectedFiles: uploadedFiles,
        }));

    };

    const handleCardTypeSelect = (selectedType) => {
        setFormData((prevState) => ({
            ...prevState,
            selectedCardType: selectedType,
        }));
    };

    const handleFormChange = (updatedData) => {
        setFormData((prevState) => ({
            ...prevState,
            ...updatedData,
        }));
    };

    const handleChange = (field, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handlePostExpertRequest = async () => {
        try {
            const payload = {
                userId: userData?.userID,
                requestType:
                    value === 0
                        ? formData.selectedCardType?.type
                        : "NOT_SELECT",
                projectType:
                    value === 1
                        ? formData.selectedCardType?.type
                        : "NOT_SELECT",
                productId: null,
                requestSummary: "",
                requestDetails: formData.requestDetails,
                status: "PENDING",
                companyName: formData.companyName,
                email: formData.email,
                contactName: formData.contactName,
                phoneNumber: formData.phone,
                requestCategory: value === 0 ? "CONSULTATION" : "HIRING",
                images: formData?.selectedFiles,
            };

            const responseExpertRequest = await postExpertRequest(payload);
            if (responseExpertRequest) {
                toast.success("신청이 완료되었습니다.");
                navigate(`/`);

            }
        } catch (err) {
            toast.error("신청이 실패했습니다.");
            console.error("Error posting expert request", err);
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setFormData({
            companyName: "",
            email: "",
            contactName: "",
            phone: "",
            selectedCardType: {},
            selectedFiles: [],
            requestDetails: "",
        });
        // setPage(1);
    };

    const handleToDetails = () => {
        navigate(`/experts/consultation-list`);
    };
    return (
        <main className="flex flex-col overflow-hidden bg-white px-20 pb-24 max-md:px-0 max-md:px-5 max-md:py-5">
            <section className="flex min-h-[192px] w-full flex-col justify-center px-72 py-14 text-center font-bold leading-none max-md:max-w-full max-md:px-5 max-md:pt-0">
                <div className="flex w-full flex-col items-center max-md:max-w-full">
                    <h1 className="text-3xl text-black">전문가 고용 및 상담</h1>
                    <div className="flex justify-center">
                        <Button
                            onClick={handleToDetails}
                            className={
                                "mt-5 w-[120px] gap-2 self-stretch rounded-[100px] border border-solid border-indigo-400 px-4 py-2.5 text-sm text-main-color"
                            }
                            label={"신청 내역 보기"}
                        />
                    </div>
                </div>
            </section>

            <div className="mt-2.5 flex w-full flex-col self-center max-md:max-w-full">
                <div className="flex justify-center border-b-[1px]">
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant="standard"
                        aria-label="full width tabs example"
                        textColor="inherit"
                        indicatorColor="primary"
                        className="rounded-[10px] pb-0"
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: "#2D375B",
                                borderRadius: "10px",
                                fontWeight: 700,
                            },
                            "& .MuiTab-root": {
                                padding: "5px 20px",
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: "#ffffff",
                                border: "1px dashed #2D375B",
                            },
                            color: "#666A79",
                            bgcolor: "#FFFFFF",
                            padding: "10px",
                        }}
                    >
                        <Tab
                            sx={{ minHeight: "36px", height: "36px" }}
                            label="전문가에게 상담하기"
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minHeight: "36px", height: "36px" }}
                            label="전문가를 고용하기"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </div>
                <TabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    sx={{ padding: 0 }}
                >
                    <form className="flex justify-center">
                        <div className="w-full max-w-[1000px]">
                            <div className="mt-5 flex w-full flex-col justify-center self-center rounded-md border border-solid border-neutral-200 px-12 py-10 text-base leading-none max-md:max-w-full max-md:px-5">
                                <div className="flex w-full flex-col rounded-xl bg-white max-md:max-w-full">
                                    <StepHeader
                                        step="1"
                                        title="연락받으실 정보를 입력하세요."
                                    />
                                    <Step1Form
                                        formData={formData}
                                        onChange={handleFormChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <StepHeader
                                    step="2"
                                    title="상담 요청할 내용을 작성하세요."
                                />
                                <ConsultationDetails
                                    // files={files}
                                    onSelectType={handleCardTypeSelect}
                                    defaultSelectedType={
                                        formData.selectedCardType
                                    }
                                    handleFilesChange={handleFilesChange}
                                    selectedFiles={formData.selectedFiles}
                                    requestDetails={formData.requestDetails} 
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="flex justify-center">
                                <Button
                                    className={
                                        "mt-14 w-80 max-w-full gap-2.5 self-center whitespace-nowrap rounded-md bg-indigo-600 px-4 py-4 text-center text-base font-bold leading-none text-white max-md:mt-10"
                                    }
                                    type="button"
                                    label={"신청하기"}
                                    onClick={handlePostExpertRequest}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>

                <TabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                    sx={{ padding: 0 }}
                >
                    <form className="flex justify-center">
                        <div className="w-full max-w-[1000px]">
                            <div className="mt-5 flex w-full flex-col justify-center self-center rounded-md border border-solid border-neutral-200 px-12 py-10 text-base leading-none max-md:max-w-full max-md:px-5">
                                <div className="flex w-full flex-col rounded-xl bg-white max-md:max-w-full">
                                    <StepHeader
                                        step="1"
                                        title="연락받으실 정보를 입력하세요."
                                    />
                                    <Step1Form
                                        formData={formData}
                                        onChange={handleFormChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <StepHeader
                                    step="2"
                                    title="상담 요청할 내용을 작성하세요."
                                />
                                <ConsultationDetailsHireAndExpert
                                    onSelectType={handleCardTypeSelect}
                                    defaultSelectedType={
                                        formData.selectedCardType
                                    }
                                    handleFilesChange={handleFilesChange}
                                    selectedFiles={formData.selectedFiles}
                                    requestDetails={formData.requestDetails}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="flex justify-center">
                                <Button
                                    className={
                                        "mt-14 w-80 max-w-full gap-2.5 self-center whitespace-nowrap rounded-md bg-indigo-600 px-4 py-4 text-center text-base font-bold leading-none text-white max-md:mt-10"
                                    }
                                    type="button"
                                    label={"신청하기"}
                                    onClick={handlePostExpertRequest}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
            </div>
        </main>
    );
}

export default ExpertConsultationForm;
