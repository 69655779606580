import React, { useState, useEffect } from "react";
import TableCommon from "../../../components/common/CommonTable/TableCommon";
import Button from "../../../components/common/Button/Button";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import { useGetListOrderPaymentMutation } from "../../../services/apiSlice";
import { useSelector } from "react-redux";
import {
    formatDateTime,
    formatPriceString,
} from "../../../utils/helper/formatters";
import ModalComponent from "../../../components/common/Modals/ModalComponent";

const PaymentHistory = () => {
    const [page, setPage] = useState(1);
    const { userData, accessToken } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const columns = [
        { label: "결제일", field: "date" },
        { label: "결제 상품 정보", field: "productInfo" },
        { label: "확장자", field: "fileType" },
        { label: "결제금액", field: "amount" },
        { label: "결제취소", field: "cancelable" },
    ];

    const initModal = {
        status: false,
        type: "",
        title: "",
        content: "",
        subTitle: "",
        footerContent: "",
    };
    const [modal, setModal] = useState(initModal);

    const [getPaymentHistoryList, { data }] = useGetListOrderPaymentMutation();
    useEffect(() => {
        const fetchLogosList = async () => {
            await getPaymentHistoryList({
                page: page,
                userId: userId,
            });
        };
        fetchLogosList();
    }, [getPaymentHistoryList, page]);

    const totalPages = data ? data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleCancelPayment = () => {
        setModal({
            ...modal,
            status: true,
            title: "결제를 취소하시겠습니까?",
            content:
                "작업 상태가 '진행중'으로 변경된 이후에는 \n 결제 취소가 불가능합니다. \n 신중히 검토 후 진행해 주시기 바랍니다.",
        });
    };

    const handleConfirmOrder = async () => {
        setModal({
            ...modal,
            status: false,
        });
    };

    const handleCloseModal = () => {
        setModal({
            ...modal,
            status: false,
        });
    };

    const statusClass =
        data?.data?.orderStatus === "IN_COMPLETED"
            ? "text-white bg-indigo-600"
            : "bg-[#E5E4F7] text-[#70709F]";
    return (
        <>
            <div className="w-full px-20 max-md:px-5">
                <TableCommon
                    columns={columns}
                    data={(data?.data || []).map((item) => ({
                        ...item,

                        date: formatDateTime(item?.createdAt),
                        productInfo: (
                            <>
                                <div className="flex flex-col">
                                    <span>
                                        로고 - {item?.receivedOrderType}
                                    </span>
                                    <span>
                                        제작물 이름 - {item?.receivedOrderType}
                                    </span>
                                </div>
                            </>
                        ),
                        fileType: item?.receivedOrderType,
                        amount: formatPriceString(item?.totalAmount),
                        cancelable:
                            item?.receivedOrderType === "PDF" ||
                            item?.receivedOrderType === "JPG" ||
                            item?.receivedOrderType === "PNG" ? (
                                <>
                                    <span>_</span>
                                </>
                            ) : (
                                <>
                                    {item?.orderStatus === "PENDING" ? (
                                        <Button
                                            disabled={true}
                                            label={"다운로드"}
                                            className={`my-auto gap-2.5 self-stretch rounded bg-[#DBDBEF] px-3 py-2.5 font-medium text-[#3533FF]`}
                                        />
                                    ) : (
                                        <Button
                                            label={"다운로드"}
                                            className={`my-auto gap-2.5 self-stretch rounded bg-indigo-600 px-3 py-2.5 font-medium text-white`}
                                            onClick={handleCancelPayment}
                                        />
                                    )}
                                </>
                            ),
                    }))}
                />
            </div>
            <div className="flex justify-center pb-24">
                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </div>

            <ModalComponent
                title={modal.title}
                titleClass="flex justify-center"
                open={modal.status}
                content={modal.content}
                subTitle={modal.subTitle}
                footerContent={modal.footerContent}
                contentClass="text-center"
                buttonClassContainer="flex flex-row-reverse"
                textAreaPlaceholder="탈퇴 사유를 입력해주세요."
                buttonClose={{
                    buttonClass:
                        "bg-white text-black px-4 py-2 rounded border border-[##E8E8E8] min-w-[165px]",
                    label: "취소",
                    onClose: () => {
                        handleCloseModal();
                    },
                }}
                buttonSubmit={{
                    buttonClass:
                        "bg-[#3533FF] text-white px-4 py-2 rounded min-w-[165px]",
                    label: "다시 만들기",
                    onSubmit: () => {
                        handleConfirmOrder();
                    },
                }}
            />
        </>
    );
};

export default PaymentHistory;
