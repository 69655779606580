import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import images from "../../../images";
import Button from "../../../components/common/Button/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
};

export default function ModalComponent({
    open = false,
    className = "w-[400px]",
    title = "",
    subTitle = "",
    titleClass = "",
    content = "",
    contentClass = "",
    showTextArea = false,
    textAreaPlaceholder = "",
    textAreaValue = "",
    footerContent = "",
    onTextAreaChange = () => {},
    hideIconClose = true,
    buttonClassContainer = "",
    buttonSubmit = {
        buttonClass: "",
        label: "",
        onSubmit: () => {},
    },
    buttonClose = {
        buttonClass: "",
        label: "",
        onClose: () => {},
    },
    onCloseIcon = () => {},
}) {
    const [textAreaContent, setTextAreaContent] = useState(textAreaValue);

    const handleTextAreaChange = (e) => {
        const value = e.target.value;
        setTextAreaContent(value);
        onTextAreaChange(value); // Callback to pass value to parent component
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={buttonClose.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={className}>
                    <div className="flex justify-between">
                        {title && (
                            <Typography
                                id="modal-modal-title"
                                className={`w-full ${titleClass}`}
                                variant="h6"
                                component="h2"
                            >
                                {title}
                            </Typography>
                        )}
                        {!hideIconClose && (
                            <img
                                loading="lazy"
                                src={images.closeIconB}
                                alt="Business Card Icon"
                                className="aspect-square w-[20px] cursor-pointer object-contain max-md:mt-10"
                                onClick={onCloseIcon}
                            />
                        )}
                    </div>
                    {subTitle && (
                        <h3 className="mt-2 font-medium">{subTitle}</h3>
                    )}
                    {content && (
                        <Typography
                            id="modal-modal-description"
                            className={`${contentClass} whitespace-pre-line text-[#444444]`}
                            sx={{ mt: 2 }}
                        >
                            {content}
                        </Typography>
                    )}
                    {footerContent && <>{footerContent}</>}
                    {showTextArea && (
                        <textarea
                            value={textAreaContent}
                            onChange={handleTextAreaChange}
                            placeholder={textAreaPlaceholder}
                            rows="4"
                            className="mt-4 w-full rounded border border-gray-300 p-2"
                        ></textarea>
                    )}
                    {(buttonSubmit || buttonClose) && (
                        <div
                            className={`${buttonClassContainer} mt-4 mt-6 flex w-full justify-center gap-2`}
                        >
                            <div>
                                {buttonSubmit.label !== "" && (
                                    <Button
                                        className={buttonSubmit.buttonClass}
                                        label={buttonSubmit.label}
                                        onClick={buttonSubmit.onSubmit}
                                    />
                                )}
                            </div>
                            <div>
                                {buttonClose.label !== "" && (
                                    <Button
                                        className={buttonClose.buttonClass}
                                        label={buttonClose.label}
                                        onClick={buttonClose.onClose}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <div></div>
                </Box>
            </Modal>
        </div>
    );
}
