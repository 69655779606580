import * as React from "react";

export default function FormInput({ formFields, onChange }) {
  return (
    <form className="flex flex-col mt-5 w-full text-base max-md:max-w-full">
      {formFields.map((field) => (
        <div
        key={field.name}
          className="flex flex-col mt-8 first:mt-0 w-full min-h-[80px] max-md:max-w-full"
        >
          {field.label && (
            <label
              className="font-bold text-neutral-700"
              htmlFor={`field-${field.name}`}
            >
              {field.label}
            </label>
          )}
          <input
            type="text"
            id={`field-${field.name}`}
            className="gap-2.5 self-stretch px-4 mt-2.5 w-full rounded-md border border-solid bg-stone-50 border-stone-50 min-h-[50px] text-zinc-500 max-md:max-w-full"
            placeholder={field.placeholder}
            value={field.value || ""}
            onChange={(e) => onChange(field.name, e.target.value)}
          />
        </div>
      ))}
    </form>
  );
}
