import React from "react";
import images from "../../images";

function FAQItem({ question, answer, isOpen, onClick }) {
    return (
        <article className="flex w-full max-w-full flex-col">
            <div
                className={`flex flex-col ${isOpen ? "pb-7" : ""} w-full font-bold ${
                    isOpen ? "" : "border-b"
                } border-neutral-200`}
            >
                <div
                    className="flex w-full cursor-pointer items-center gap-5"
                    onClick={onClick}
                >
                    <div className="flex min-w-[240px] flex-grow items-center gap-2.5 py-7">
                        <span className="text-xl leading-tight text-main-color">
                            Q.
                        </span>
                        <p className="text-base leading-relaxed text-zinc-800">
                            {question}
                        </p>
                    </div>
                    <img
                        loading="lazy"
                        src={isOpen ? images.arrowTWIcon : images.arrowBWIcon}
                        alt={isOpen ? "Collapse" : "Expand"}
                        className="ml-auto aspect-square w-4 object-contain"
                    />
                </div>

                {isOpen && (
                    <div className="w-full self-stretch rounded bg-main-color px-8 py-6 text-base leading-6 text-zinc-100">
                        {answer}
                    </div>
                )}
            </div>
        </article>
    );
}

export default FAQItem;
