export function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


export const getSelectedValue = (object) => {
    if (!object) return null;
  
    if (Array.isArray(object.value)) {
      return object.selectedValue || object.value[0];
    }
  
    if (typeof object.value === "object") {
      return object.selectedValue || object.value;
    }
  
    return object.selectedValue || object.value;
  };
  


  export const findSpecByLabel = (specs, label) => {
    if (!Array.isArray(specs)) return null;
    return specs.find((spec) => spec.label === label);
};


export const getFieldValueByName = (formFields, fieldName) => {
    const field = formFields.find((f) => f.name === fieldName);
    return field ? field.value : ""; 
  };
  