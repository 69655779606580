export function formatPrice(price) {
  if (typeof price !== 'number' || isNaN(price)) {
    return "0원"; 
  }
  return `${price.toLocaleString('ko-KR')}원`;
}
export function formatPriceString(price) {
  if (typeof price === 'string') {
    price = parseFloat(price.replace(/,/g, ''));
  }

  if (typeof price !== 'number' || isNaN(price)) {
    return "0원"; 
  }

  return `${price.toLocaleString('ko-KR')}원`;
}

export const extractNumberFromString = (priceString) => {
  if (!priceString) return 0;
  const cleanedPrice = priceString.replace(/[^\d,]/g, "");
  return parseInt(cleanedPrice.replace(/,/g, ""), 10);
};


export const extractQuantity = (quantityString) => {
  if (!quantityString || typeof quantityString !== "string") {
      return 0;
  }

  const extractedQuantity = quantityString.replace(/\D/g, "");

  return parseInt(extractedQuantity, 10) || 0;
};

export const formatDateTime = (isoDate) => {
  if (!isoDate) return ""; // Return empty string if no date is provided

  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};