// src/services/endpoints/authEndpoints.js

import { AUTH_ENDPOINTS } from "../config/apiconfig";
import { apiSlice } from "../apiSlice";
import { setStatusLoading } from "./../../redux/featuresSlice/commonSlice"; // Import setStatusLoading từ commonSlice

export const authEndpoints = (builder) => ({
    login: builder.mutation({
        query: (credentials) => ({
            url: AUTH_ENDPOINTS.LOGIN,
            method: "POST",
            data: credentials,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
                const { data } = await queryFulfilled;
                localStorage.setItem("token", data.token);
            } catch (error) {
                console.error("Login error:", error);
            }
        },
    }),

    logout: builder.mutation({
        query: () => ({
            url: AUTH_ENDPOINTS.LOGOUT,
            method: "GET",
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
                localStorage.removeItem("token");
                dispatch(apiSlice.util.resetApiState());
            } catch (error) {
                console.error("Logout error:", error);
            }
        },
    }),

    loginWithGoogle: builder.mutation({
        query: (credentials) => ({
            url: AUTH_ENDPOINTS.LOGIN_WITH_GOOGLE,
            method: "GET",
            data: credentials,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
                const { data } = await queryFulfilled;
                localStorage.setItem("token", data.token);
            } catch (error) {
                console.error("Login error:", error);
            }
        },
    }),

    generateOTP: builder.mutation({
        query: (credentials) => ({
            url: AUTH_ENDPOINTS.GENERATE_OTP,
            method: "POST",
            data: credentials,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
                const { data } = await queryFulfilled;
                localStorage.setItem("dataGenerateOTP", data);
            } catch (error) {
                console.error("Login error:", error);
            }
        },
    }),

    verifyOTP: builder.mutation({
        query: (credentials) => ({
            url: AUTH_ENDPOINTS.VERIFY_OTP,
            method: "POST",
            data: credentials,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
                const { data } = await queryFulfilled;
                localStorage.setItem("verifyOTP", data);
            } catch (error) {
                console.error("Login error:", error);
            }
        },
    }),

      updateUserInfo: builder.mutation({
            query: (body) => ({
                url: AUTH_ENDPOINTS.UPDATE_USER_INFO,
                method: "POST",
                data: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    console.error("Logout error:", error);
                }
            },
        }),

        cancelUserInfo: builder.mutation({
            query: (body) => ({
                url: AUTH_ENDPOINTS.CANCEL_USER_INFO,
                method: "POST",
                data: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    console.error("Logout error:", error);
                }
            },
        }),
});
