import React, { useState } from "react";
import InputField from "../../../components/common/Input/InputField";
import Button from "../../../components/common/Button/Button";
import { Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../../utils/helper/function";
import StepHeader from "../../LayoutCommon/StepHeader";
import TabPanel from "../../../components/common/Tabs";
import { useTheme } from "@emotion/react";
import ConsultationDetails from "../ConsultationDetails";
import ConsultationRequestList from "./ConsultationRequestList";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import {
    consultationRequests,
    consultationRequestsTab2,
} from "../../../components/common/dataFake/imageData";
import { useGetExpertRequestsQuery } from "../../../services/apiSlice";
import { useSelector } from "react-redux";

const files = [
    { name: "logo001.png" },
    { name: "logo002.png" },
    { name: "logo003.png" },
    { name: "logo004.png" },
    { name: "logo005.png" },
];

function ExpertConsultationList() {
    const theme = useTheme();
    const [value, setValue] = useState(0); // 0: CONSULTATION, 1: HIRING
    const [page, setPage] = useState(1);
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const requestCategory = value === 0 ? "CONSULTATION" : "HIRING";

    const { data, isLoading, error } = useGetExpertRequestsQuery({
        order: "DESC",
        page,
        take: 10,
        requestCategory,
        userId: userId,
    });
    const currentImages = data?.data || [];
    const totalPages = data ? data?.data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };
    console.log("data", data);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setPage(1);
    };


    console.log("data", data);
    
    return (
        <main className="flex flex-col overflow-hidden bg-white px-20 pb-24 max-md:px-0 max-md:px-5 max-md:pt-0">
            <section className="flex min-h-[192px] w-full flex-col justify-center px-72 py-14 text-center font-bold leading-none max-md:max-w-full max-md:px-5">
                <div className="flex w-full flex-col items-center max-md:max-w-full">
                    <h1 className="text-3xl text-black">전문가 고용 및 상담</h1>
                </div>
            </section>

            <div className="mt-2.5 flex w-full flex-col self-center max-md:max-w-full">
                <div className="flex justify-center border-b">
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant="standard"
                        aria-label="full width tabs example"
                        textColor="inherit"
                        indicatorColor="primary"
                        className="rounded-[10px] pb-0"
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: "#2D375B",
                                borderRadius: "10px",
                                fontWeight: 700,
                            },
                            "& .MuiTab-root": {
                                padding: "5px 20px",
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: "#ffffff",
                                border: "1px dashed #2D375B",
                            },
                            color: "#666A79",
                            bgcolor: "#FFFFFF",
                            padding: "10px",
                        }}
                    >
                        <Tab
                            sx={{ minHeight: "36px", height: "36px" }}
                            label="전문가 상담 요청 "
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minHeight: "36px", height: "36px" }}
                            label="전문가 고용상담 요청 "
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </div>
                <TabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    sx={{ padding: 0 }}
                >
                    <form className="flex justify-center">
                        <div className="max-w-[1600px]">
                            <ConsultationRequestList
                                data={data?.data|| []}
                                tab={0}
                            />
                        </div>
                    </form>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                    sx={{ padding: 0 }}
                >
                    <form className="flex justify-center">
                        <div className="max-w-[1600px]">
                            <ConsultationRequestList
                                data={data?.data || []}
                                tab={1}
                            />
                        </div>
                    </form>
                </TabPanel>
                <div className="flex justify-center border-t">
                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
                </div>
            </div>
        </main>
    );
}

export default ExpertConsultationList;
