import React, { useState,useEffect } from "react";
import FileAttachment from "./FileAttachment";
import CheckboxGroup from "../../components/common/CheckBox/CheckBox";

function ConsultationDetailsHireAndExpert({ files, onSelectType ,handleFilesChange,selectedFiles,requestDetails, handleChange}) {
    const [cardTypes, setCardTypes] = useState([
        { name: "전문 브랜딩 로고", icon: "ext_5-", active: true, id: 0, type: "BRAND_LOGO" },
        { name: "패키지 디자인", icon: "ext_5-", active: false, id: 1, type: "PACKAGE_DESIGN" },
        { name: "기타 요청 (상담 후 결정)", icon: "ext_6-", active: false, id: 2, type: "OTHER_PROJECT" },
    ]);
  
    const handleSelectType = (selectedType) => {
        const updatedTypes = cardTypes.map((type) => ({
            ...type,
            active: type.id === selectedType.id,
        }));
        setCardTypes(updatedTypes);

        onSelectType({
            name: selectedType.name,
            type: selectedType.type,
            id: selectedType.id,
        });
    };
    useEffect(() => {
        const defaultType = cardTypes.find((type) => type.type === "BRAND_LOGO");
        if (defaultType) {
            handleSelectType(defaultType);
        }
    }, []);

    return (
        <section className="mt-5 flex w-full max-w-[1000px] flex-col self-center border border-solid border-neutral-200 px-12 py-10 max-md:max-w-full max-md:px-5">
            <div className="flex w-full flex-col justify-center leading-none max-md:max-w-full">
                <h3 className="text-base font-bold text-neutral-700 max-md:max-w-full">
                    간단 요청 사항
                </h3>
                <div className="mt-4 flex flex-wrap items-center gap-10 self-start text-center text-lg text-zinc-500 max-md:max-w-full max-md:gap-8">
                    <div
                        className={`my-auto flex items-center gap-2.5 self-stretch whitespace-nowrap`}
                    >
                        <CheckboxGroup
                            cardTypes={cardTypes}
                            handleSelectType={handleSelectType}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-10 flex w-full flex-col max-md:max-w-full">
                <label
                    htmlFor="detailsTextarea"
                    className="text-base font-bold leading-none text-neutral-700 max-md:max-w-full"
                >
                    상세 내용
                </label>
                {/* {files.map((file, index) => (
                    <FileListItem key={index} fileName={file.name} />
                ))} */}
                <textarea
                    id="detailsTextarea"
                    className="mt-4 min-h-[320px] w-full gap-2.5 rounded-md border border-solid border-stone-50 bg-stone-50 px-5 pb-64 pt-5 text-base leading-6 text-zinc-500 max-md:max-w-full max-md:pb-24"
                    placeholder="전문가에게 요청하실 내용을 입력해주세요.&#10;내용 확인 후 상담이 진행됩니다."
                    value={requestDetails || ""} // Gán giá trị từ props vào value
                    onChange={(e) => handleChange("requestDetails", e.target.value)}
                ></textarea>
            </div>
            <FileAttachment files={selectedFiles} onFilesChange={handleFilesChange} />

        </section>
    );
}



export default ConsultationDetailsHireAndExpert;
