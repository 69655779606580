import React, { useState, useEffect } from "react";
import CommonTabs from "../../../components/common/Tabs/CommonTabs";
import { formatPrice } from "../../../utils/helper/formatters";
const CardSpecificationsTypeSecond = ({ onSelectedDataChange }) => {
    const options = [
        { label: "크라프트 명함" },
        { label: "블랙 명함" },
        { label: "벨벳 명함" },
        { label: "투명 명함" },
        { label: "고급 용지 명함" },
    ];

    const specifications = {
        "크라프트 명함": [
            { label: "용지", value: "크라프트 보드 300g" },
            { label: "인쇄도수", value: "4도 인쇄 + 양면" },
            { label: "코팅", value: "무광 코팅" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: ["200매", "400매", "600매"],
                type: "select",
            },
        ],
        "블랙 명함": [
            { label: "용지", value: "매트 블랙 380g" },
            {
                label: "인쇄도수",
                value: ["앙면은박", "양면금박"],
                type: "select",
            },
            { label: "코팅", value: " " },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: ["200매", "400매", "600매"],
                type: "select",
            },
        ],
        "벨벳 명함": [
            { label: "용지", value: "벨벳 화이트 359g" },
            { label: "인쇄도수", value: "4도 인쇄" },
            { label: "코팅", value: " " },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: [
                    "금박 (유광)",
                    "금박 (무광)",
                    "은박 (유광)",
                    "은박 (무광)",
                    "청박 (유광)",
                    "적박 (유광)",
                    "녹박 (유광)",
                    "먹박 (유광)",
                    "홀로그램박 (은펄)",
                    "홀로그램박 (별)",
                    "홀로그램박 (물방울)",
                    "로즈골드박 (유광)",
                    "퍼플박 (유광)",
                    "백박 (무광)",
                ],
                type: "select",
            },
            {
                label: "수량",
                value: ["200매"],
                type: "select",
            },
        ],
        "투명 명함": [
            { label: "용지", value: "PET 300" },
            {
                label: "인쇄도수",
                value: "4도 인쇄 + 단면",
            },
            { label: "코팅", value: " " },
            { label: "규격", value: "86X54" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: ["200매"],
                type: "select",
            },
        ],
        "고급 용지 명함": [
            {
                label: "용지",
                value: ["띤또레또", "에그쉘", "듀오화이트", "엑스트라폴라"],
                type: "select",
            },
            {
                label: "인쇄도수",
                value: "4도 인쇄 + 양면",
            },
            { label: "코팅", value: "무광 코팅" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: ["200매", "400매", "600매"],
                type: "select",
            },
        ],
    };

    const priceMappings = {
        "크라프트 명함": {
            "200매": 13400,
            "400매": 21400,
            "600매": 29300,
        },
        "블랙 명함": {
            "200매": 13400,
            "400매": 21400,
            "600매": 29300,
        },
        "벨벳 명함": {
            "200매": 53500,
        },
        "투명 명함": {
            "200매": 28500,
        },
        "고급 용지 명함": {
            "200매": 13400,
            "400매": 21400,
            "600매": 29300,
        },
    };

    const [activeTab, setActiveTab] = useState(options[0]?.label);
    const [specs, setSpecs] = useState(specifications[activeTab] || []);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        setSpecs(specifications[activeTab] || []);
        const defaultAmount = priceMappings[activeTab]?.["200매"] || 0;
        setTotalAmount(defaultAmount);
        sendSelectedData(specifications[activeTab], defaultAmount);
    }, [activeTab]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleSelectChange = (index, selectedValue, label) => {
        const updatedSpecs = specs.map((spec, i) =>
            i === index ? { ...spec, selectedValue } : spec,
        );
        setSpecs(updatedSpecs);

        if (label === "수량") {
            const selectedQuantity = updatedSpecs.find(
                (spec) => spec.label === "수량",
            )?.selectedValue;
            const price = priceMappings[activeTab]?.[selectedQuantity] || 0;

            setTotalAmount(price);
            sendSelectedData(updatedSpecs, price);
        } else {
            sendSelectedData(updatedSpecs, totalAmount);
        }
    };

    const sendSelectedData = (specsData, price) => {
        if (onSelectedDataChange) {
            onSelectedDataChange({ specs: specsData, totalAmount: price , selectedOption: activeTab,});
        }
    };

    const renderTabPanels = () => {
        return options.map((option) => {
            const specsForOption = specifications[option.label] || [];
            return (
                <div key={option.label}>
                    {specsForOption.map((spec, index) => (
                        <div
                            key={index}
                            className="mt-2 flex items-center gap-4"
                        >
                            <div className="w-24 font-bold text-gray-700">
                                {spec.label}
                            </div>
                            {spec.type === "select" ? (
                                <select
                                    onChange={(e) =>
                                        handleSelectChange(
                                            index,
                                            e.target.value,
                                            spec.label,
                                        )
                                    }
                                    className="rounded border border-gray-300 p-2"
                                >
                                    {spec.value.map((item, idx) => (
                                        <option key={idx} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <span>{spec.value}</span>
                            )}
                        </div>
                    ))}
                </div>
            );
        });
    };

    return (
        <div>
            <div className="flex w-full flex-col max-md:max-w-full">
                <h3 className="text-2xl font-bold leading-none text-main-color">
                    고급명함
                </h3>
                <p className="mt-2.5 text-base leading-6 text-neutral-700">
                    특별한 인상을 줄 수 있는 고급 종이와 인쇄 기법을 사용하여
                    <br />
                    명함의 질감과 디자인에 차별화를 더한 명함입니다.
                </p>
            </div>
            <CommonTabs
                labels={options.map((o) => o.label)}
                panels={renderTabPanels()}
                onTabSelect={handleTabChange}
            />

            <div className="mt-8 flex min-h-[50px] w-full flex-wrap items-center justify-between gap-10 rounded-md bg-stone-50 px-5 text-lg font-bold leading-none max-md:max-w-full">
                <div className="my-auto self-stretch text-zinc-800">총 결제 금액</div>
                <div className="my-auto self-stretch text-right text-main-color">
                    {formatPrice(totalAmount)}
                </div>
            </div>
        </div>
    );
};

export default CardSpecificationsTypeSecond;
