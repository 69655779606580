import React, { useState, useEffect } from "react";
import { formatPrice } from "../../../utils/helper/formatters";

function CardSpecifications({ onSelectedDataChange }) {
    const options = [
        { label: "크라프트 명함" },
    ];

    const specifications = {
        "크라프트 명함": [
            { label: "용지", value: "스노우지 250g" },
            { label: "인쇄도수", value: "4도 인쇄 + 양면" },
            { label: "코팅", value: "무광 코팅" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: " "
            },
            {
                label: "수량",
                value: ["200매", "400매", "600매", "800매", "1000매"],
                type: "select",
            },
        ],
    };

    const priceMappings = {
        "크라프트 명함": {
            "200매": 9900,
            "400매": 15192,
            "600매": 20376,
            "800매": 25452,
            "1000매": 30500,
        },
    };

    const [activeTab, setActiveTab] = useState(options[0]?.label);
    const [specs, setSpecs] = useState(specifications[activeTab] || []);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        setSpecs(specifications[activeTab] || []);
        const defaultAmount = priceMappings[activeTab]?.["200매"] || 0;
        setTotalAmount(defaultAmount);
        sendSelectedData(specifications[activeTab], defaultAmount);
    }, [activeTab]);

    const handleSelectChange = (index, selectedValue, label) => {
        const updatedSpecs = specs.map((spec, i) =>
            i === index ? { ...spec, selectedValue } : spec
        );
        setSpecs(updatedSpecs);

        if (label === "수량") {
            const selectedQuantity = updatedSpecs.find(
                (spec) => spec.label === "수량"
            )?.selectedValue;
            const price = priceMappings[activeTab]?.[selectedQuantity] || 0;

            setTotalAmount(price);
            sendSelectedData(updatedSpecs, price);
        } else {
            sendSelectedData(updatedSpecs, totalAmount);
        }
    };

    const sendSelectedData = (specsData, price) => {
        if (onSelectedDataChange) {
            onSelectedDataChange({ specs: specsData, totalAmount: price });
        }
    };

    return (
        <div className="flex max-w-[600px] min-w-[240px] w-full flex-col max-md:max-w-full">
            <div className="flex w-full flex-col max-md:max-w-full">
                <h3 className="text-2xl font-bold leading-none text-main-color">
                    일반명함
                </h3>
                <p className="mt-2.5 text-base leading-6 text-neutral-700">
                    일반적으로 많이 사용되는 명함의 종이와 인쇄방법으로
                    <br />
                    실용적이고 경제적인 선택으로, 기본적인 정보 전달에
                    적합합니다.
                </p>
            </div>
            <div className="mt-8 flex w-full flex-col text-base leading-none">
                <div className="flex w-full flex-col rounded px-5 py-8">
                    {specs.map((spec, index) => (
                        <div
                            key={index}
                            className="mt-2 flex min-h-[30px] w-full flex-wrap items-center gap-8 first:mt-0"
                        >
                            <div className="my-auto w-[60px] self-stretch font-bold text-neutral-700">
                                {spec.label}
                            </div>
                            <div className="my-auto w-[180px] self-stretch">
                                {spec.type === "select" ? (
                                    <select
                                        onChange={(e) =>
                                            handleSelectChange(index, e.target.value, spec.label)
                                        }
                                        className="rounded border border-gray-300 p-2"
                                    >
                                        {spec.value.map((item, idx) => (
                                            <option key={idx} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <span className="text-neutral-600">{spec.value}</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-8 flex min-h-[50px] w-full flex-wrap items-center justify-between gap-10 rounded-md bg-stone-50 px-5 text-lg font-bold leading-none max-md:max-w-full">
                    <div className="my-auto self-stretch text-zinc-800">총 결제 금액</div>
                    <div className="my-auto self-stretch text-right text-main-color">
                        {formatPrice(totalAmount)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardSpecifications;
