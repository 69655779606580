import React, { useState } from 'react';
import InputField from '../../components/common/Input/InputField';
import TabsCreateLogo from './TabPanel';

export default function LogoUploader({ onInputChange, selectedTab, setSelectedTab, onFileSelect, formErrors ,disableTab,handleIdFromImageList}) {
    const [workName, setWorkName] = useState(""); 

    const handleInputChange = (event) => {
        setWorkName(event.target.value);
        onInputChange(event.target.value);
    };

    return (
        <section className="flex flex-col justify-center px-4 md:px-12 py-10 mt-5 rounded-md border border-solid border-neutral-200">
            <div className="flex flex-col w-full bg-white rounded-xl">
                <InputField
                    label="작업명"
                    description="작업명은 로고 구매 시, 구매하신 제작물을 구분하기 위해 등록됩니다."
                    placeholder="작업명을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                    value={workName}
                    onChange={handleInputChange}
                />
                {/* Display validation error for workName */}
                {formErrors.workName && (
                    <div className="mt-1 text-sm text-red-500">
                        {formErrors.workName}
                    </div>
                )}

                <div className="flex flex-col mt-10 w-full">
                    <TabsCreateLogo
                      onSelectId={handleIdFromImageList}
                        value={selectedTab}
                        setValue={setSelectedTab}
                        onFileSelect={onFileSelect}
                        disableTab={disableTab}
                    />
                </div>
            </div>
        </section>
    );
}
