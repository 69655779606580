import React from "react";
import Button from "../../../components/common/Button/Button";
import { formatDateTime } from "../../../utils/helper/formatters";

function InquiryQuestionItem({
    queryId,
    question,
    answeredAt,
    status,
    handleClick,
    number,
}) {
    const statusClass =
        status === "IN_COMPLETED"
            ? "text-white bg-indigo-600"
             : "bg-[#E5E4F7] text-[#70709F]"

    return (
        <article className="flex w-full flex-wrap items-center gap-8 border-b border-neutral-200 p-8 max-md:max-w-full max-md:px-5">
            <h2 className="my-auto self-stretch text-xl font-bold text-indigo-950">
                {number}
            </h2>
            <div className="my-auto flex min-w-[240px] flex-1 shrink basis-0 flex-wrap items-center justify-between gap-2.5 self-stretch text-base max-md:max-w-full">
                <div className="my-auto flex w-[800px] min-w-[240px] flex-col self-stretch max-md:max-w-full">
                    <h3 className="gap-1.5 self-start font-medium text-zinc-800">
                        {question}
                    </h3>
                    <time className="mt-2.5 text-neutral-700">
                        {formatDateTime(answeredAt)}
                    </time>
                </div>

                <Button
                    onClick={() => handleClick({ queryId: number })}
                    label={
                        status === "IN_COMPLETED" ? "답변 대기" : "답변 완료"
                    }
                    className={`my-auto gap-2.5 self-stretch rounded px-3 py-2.5 font-medium ${statusClass}`}
                />
            </div>
        </article>
    );
}

export default InquiryQuestionItem;
