import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
import NotificationHeader from "./NotificationHeader";
import { fakeDataNoti } from "../../components/common/dataFake/imageData";
import { useGetAlarmListMutation, useRealAllAlarmMutation ,useDeleteAllAlarmMutation} from "../../services/apiSlice";
import images from "../../images";
import { formatDateTime } from "../../utils/helper/formatters";

const NotificationList = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(6);
    const [getAlarmList, { data }] = useGetAlarmListMutation();
    const [realAllAlarm] = useRealAllAlarmMutation();
    const [deletelAllAlarm] = useDeleteAllAlarmMutation();


    useEffect(() => {
        const fetchAlarmList = async () => {
            await getAlarmList({
                page: page,
                take: take
            });
        };
        fetchAlarmList();
    }, [getAlarmList, page, take]);


    console.log("datat", data);
    
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                setNotifications(fakeDataNoti);
                setLoading(false);
            } catch (err) {
                setError("알림을 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    const handleMarkAllAsRead =async () => {
        console.log('acccction');
        
        if (data && Array.isArray(data?.data)) {
            const idArray = data?.data.map((item) => ({ id: item.id }));
            console.log("Mảng ID:", idArray);
            const body ={
                alarmIds:idArray
            }
            try {
                const response = await realAllAlarm(body).unwrap();
                getAlarmList()
                console.log("response",response);
                
            } catch (err) {
                console.error("Error:", err);
            }
        }
        
    };

    const handleDeleteAll = async() => {
         
        if (data && Array.isArray(data?.data)) {
            const idArray = data?.data.map((item) => ({ id: item.id }));
            console.log("Mảng ID:", idArray);
            const body ={
                alarmIds:idArray
            }
            try {
                const response = await deletelAllAlarm(body).unwrap();
                getAlarmList()
                console.log("response",response);
                
            } catch (err) {
                console.error("Error:", err);
            }
        }
        
        // setNotifications([]);
    };

    const loadMoreNotifications = () => {
        setTake((prevTake) => prevTake + 6);
    };

    if (loading) {
        return (
            <div className="flex h-screen items-center justify-center">
                로딩 중...
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen items-center justify-center text-red-500">
                {error}
            </div>
        );
    }


    console.log("dataa",data?.data);
    
    return (
        <main className="flex flex-col">
            <section
                data-layername="알림목록"
                className="flex w-full flex-col overflow-hidden bg-white max-md:max-w-full"
            >
                <header
                    data-layername="타이틀"
                    className="flex min-h-[139px] w-full flex-col justify-center whitespace-nowrap px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5"
                >
                    <h1 data-layername="인쇄정보입력하기">알림</h1>
                </header>
                <div className="z-10 flex w-[828px] max-w-full flex-col items-end self-center pb-14">
                    <NotificationHeader
                        onMarkAllAsRead={handleMarkAllAsRead}
                        onDeleteAll={handleDeleteAll}
                    />
                </div>
                <div className="z-10 -mt-2.5 mb-0 ml-3.5 flex h-[600px] w-[828px] max-w-full flex-col items-end self-center overflow-x-scroll max-md:mb-2.5">
                    <div className="mr-7 mt-3.5 flex flex-col self-stretch max-md:mr-2.5 max-md:max-w-full">
                        {data?.data?.length > 0 ? (
                            data?.data.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    title={notification?.title}
                                    content={notification?.content}
                                    date={formatDateTime(notification?.updatedAt)}
                                    iconSrc={notification?.isRead === false? images?.IconNoticationActive : images?.IconNoticationInActive}
                                />
                            ))
                        ) : (
                            <div className="flex h-64 items-center justify-center text-gray-500">
                                알림이 없습니다.
                            </div>
                        )}
                     
                    </div>
                </div>
                <div className="flex justify-center py-14">
                    <button
                        onClick={loadMoreNotifications}
                        className="min-h-[50px] max-w-[320px] flex-auto gap-2.5 self-start whitespace-nowrap rounded-md bg-slate-50 px-4 py-2 text-center text-base font-bold leading-none text-slate-500 transition-colors hover:bg-slate-100"
                    >
                        더보기
                    </button>
                </div>
            </section>
        </main>
    );
};

export default NotificationList;
