import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InquiryQuestionItem from "./InquiryQuestionItem";
import images from "../../../images";
import Button from "../../../components/common/Button/Button";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import { useGetUserQueryMutation } from "../../../services/apiSlice";

function InquiryList({ onNewInquiry }) {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [getUserQueyList, { data }] = useGetUserQueryMutation();
    useEffect(() => {
        const fetchLogosList = async () => {
            await getUserQueyList({
                page: page,
            });
        };
        fetchLogosList();
    }, [getUserQueyList, page]);


    const handleItemClick = () => {
        onNewInquiry();
    };
    const goToDetailsInquiry = (question) => {
        navigate(`/details-inquiry/${question}`);
    };
    const totalPages = data ? data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };
    return (
        <main className="flex flex-col rounded-none">
            <section className="flex w-full flex-col leading-none max-md:max-w-full">
                {data?.data.map((question, index) => (
                    <InquiryQuestionItem
                        key={index}
                        number={question?.queryId}
                        question={question?.question}
                        answeredAt={question?.answeredAt ? question?.answeredAt : question?.createdAt}
                        status={question?.status}
                        handleClick={(question) =>
                            goToDetailsInquiry(question?.queryId)
                        }
                    />
                ))}
            </section>
            <div className="my-20 flex w-full max-w-full flex-wrap items-center justify-between gap-20 self-end">
                <div className="flex flex-1 justify-center"></div>
                <div className="flex-2 flex content-center items-center justify-center text-center">
                    <PaginationComponent
                        className="mt-10"
                        count={totalPages}
                        page={page}
                        onPageChange={handlePageChange}
                        showFirstButton={true}
                        showLastButton={true}
                    />
                </div>
                <div className="flex flex-1 justify-center">
                    <Button
                        onClick={handleItemClick}
                        iconFirst={images.penCil}
                        label={"의하기"}
                        className="flex min-h-[40px] items-center gap-1 whitespace-nowrap rounded-md border border-solid border-neutral-300 pl-3 pr-5 text-base font-semibold leading-none text-zinc-800"
                    />
                </div>
            </div>
        </main>
    );
}

export default InquiryList;
