import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PurchaseCompleteTitle from "./PurchaseCompleteTitle";
import PurchaseInfo from "./PurchaseInfo";
import Button from "../../../components/common/Button/Button";
import PurchaseInfoSecond from "./PurchaseInfoSecond";
import ImageComponent from "./../../../components/FooterBanner";

const PurchaseCompletePage = () => {
    const [state, setState] = useState(1);
    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };
    const [images, setImages] = useState({
        images: { logo: null, businessCard: null },
    });

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const logoImageFooter = getImageByType("logo-footer");
            const businessCard = getImageByType("business-card-footer");

            if (logoImageFooter.length > 0) {
                setImages((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        logo: logoImageFooter[0]?.filePath,
                        businessCard: businessCard[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);

    return (
        <div className="align-items-center flex flex-col justify-center text-center">
            <main className="mx-auto max-w-[500px]">
                <PurchaseCompleteTitle />
                <PurchaseInfoSecond />
                <div className="mt-9 flex max-w-full flex-col justify-center text-center text-base font-bold leading-none">
                    <div className="flex w-full flex-wrap items-start gap-2.5 max-md:max-w-full">
                        <Button
                            label={"결제 내역 확인"}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                        />
                        <Button
                            label={"파일 다운로드"}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md bg-indigo-600 px-4 text-white"
                        />
                    </div>
                    <a href="#" className="mt-9 self-center text-zinc-800">
                        메인으로
                    </a>
                </div>
            </main>
            <div className="mt-40 flex w-full flex-col self-stretch overflow-hidden bg-stone-300 max-md:mt-10 max-md:max-w-full">
                <ImageComponent src={images.images.businessCard} />
                <ImageComponent src={images.images.logo} className="mt-0" />
            </div>
        </div>
    );
};

export default PurchaseCompletePage;
