import React, { useEffect, useState } from "react";
import CustomerSupportFAQSection from "./CustomerSupportFAQ/CustomerSupportFAQLayout";
import NotificationList from "./NoticeFAQ/NoticationList";
import InquiryList from "./Inquiry/InquiryList";
import InquiryForm from "./Inquiry/WriteInquiry/InquiryForm";
import UsageAndPriceGuideLayout from "./UsageAndPriceGuide/UsageAndPriceGuideLayout";

const tabs = [
    { label: "자주 묻는 질문" },
    { label: "공지사항" },
    { label: "1:1 문의" },
    { label: "이용 및 가격안내" },
];

const CustomerSupportLayout = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);
    const [showNewInquiry, setShowNewInquiry] = useState(false);
    const [statusSuccess, setStatusSuccess] = useState(false);

    const handleTabClick = (tabLabel) => {
        setActiveTab(tabLabel);
        setShowNewInquiry(false);
    };

    const goToCreateInquiry = () => {
        setStatusSuccess(false);
        setShowNewInquiry(true);
    };
    const handleStatusSuccess = (isSuccess) => {
        if (isSuccess) {
            setStatusSuccess(isSuccess);
        }
    };

    return (
        <main className="flex flex-col overflow-hidden bg-white">
            <section
                data-layername="타이틀"
                className="flex min-h-[139px] w-full flex-col justify-center whitespace-nowrap py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5"
            >
                <div className="flex max-w-full flex-col items-center">
                    <h1 data-layername="인쇄정보입력하기">고객지원</h1>
                </div>
            </section>

            <nav className="flex w-full max-w-[1240px] flex-wrap items-center justify-center gap-4 self-center border-b border-slate-300 text-xl leading-none text-gray-500 max-md:max-w-full">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => handleTabClick(tab.label)}
                        className={`my-auto self-stretch px-6 pb-4 ${
                            tab.label === activeTab
                                ? "border-b-2 border-slate-700 font-bold text-slate-700"
                                : ""
                        } border-dotted max-md:px-5`}
                    >
                        {tab.label}
                    </button>
                ))}
            </nav>

            <section className="mt-8 flex w-full flex-col items-center">
                {activeTab === "자주 묻는 질문" && (
                    <>
                        <CustomerSupportFAQSection />
                    </>
                )}
                {activeTab === "공지사항" && (
                    <>
                        <NotificationList />
                    </>
                )}
                {activeTab === "1:1 문의" && (
                    <>
                        {!showNewInquiry || statusSuccess ? (
                            <InquiryList onNewInquiry={goToCreateInquiry} />
                        ) : (
                            <InquiryForm statusSuccess={handleStatusSuccess} />
                        )}
                    </>
                )}
                {activeTab === "이용 및 가격안내" && (
                    <>
                        <UsageAndPriceGuideLayout />
                    </>
                )}
            </section>
        </main>
    );
};

export default CustomerSupportLayout;
