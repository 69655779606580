// src/utils/util.js
export const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };
  


  export const addDays = (date, days) => {
    const result = new Date(date); 
    result.setDate(result.getDate() + days); 
    return result;
};