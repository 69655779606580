import React, { useState, useEffect } from "react";
import FAQSidebar from "./FAQSidebar";
import FAQItem from "./FAQItem";
import { useGetFAQALLQuery } from "../../../services/apiSlice";

function CustomerSupportFAQSection() {
    const [activeCategory, setActiveCategory] = useState("all");
    const [faqData, setFaqData] = useState([]);
    const { data: fetchedFAQs, isLoading, error } = useGetFAQALLQuery();

    useEffect(() => {
        if (fetchedFAQs) {
            const initializedFAQs = fetchedFAQs.map((category) => ({
                ...category,
                faqs: category.faqs.map((faq) => ({
                    ...faq,
                    isOpen: false,  
                })),
            }));
            setFaqData(initializedFAQs);
        }
    }, [fetchedFAQs]);

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
    };

    const toggleFAQ = (categoryIndex, faqIndex) => {
        const updatedFaqData = [...faqData];
        const faqItem = updatedFaqData[categoryIndex].faqs[faqIndex];
        faqItem.isOpen = !faqItem.isOpen; // Toggle isOpen
        setFaqData(updatedFaqData);
    };

    return (
        <main className="mb-40 mt-20 flex flex-col gap-5 px-20 max-md:px-5 md:flex-row">
            <FAQSidebar
                activeCategory={activeCategory}
                onCategoryChange={handleCategoryChange}
            />
            <section className="flex w-full flex-col md:ml-5 ">
                <div className="flex max-w-full grow flex-col">
                    {faqData?.map((category, categoryIndex) => (
                        category.category === activeCategory && (
                            <div key={categoryIndex}>
                                {category.faqs.map((item, faqIndex) => (
                                    <FAQItem
                                        key={faqIndex}
                                        {...item}
                                        isOpen={item.isOpen}  // Pass isOpen to FAQItem
                                        onClick={() => toggleFAQ(categoryIndex, faqIndex)} // Correctly pass categoryIndex and faqIndex
                                    />
                                ))}
                            </div>
                        )
                    ))}
                </div>
            </section>
        </main>
    );
}

export default CustomerSupportFAQSection;
