import React from "react";
import CheckboxGroup from "../../../components/common/CheckBox/CheckBox";

function CardTypeSelect({ onSelectType, selectedCardType }) {
  const cardTypes = [
    { name: "일반명함", icon: "ext_5-", active: selectedCardType.id === 1, id: 1, type: "A" },
    { name: "고급명함", icon: "ext_6-", active: selectedCardType.id === 2, id: 2, type: "B" },
    { name: "커스텀명함", icon: "ext_6-", active: selectedCardType.id === 3, id: 3, type: "C" },
  ];

  const handleSelectType = (selectedType) => {
    onSelectType(selectedType);
  };

  return (
    <section className="flex flex-col justify-center self-start leading-none max-md:px-5">
      <h2 className="text-2xl font-bold text-main-color">STEP 1.</h2>
      <p className="mt-2.5 text-xl font-semibold text-neutral-700">
        명함 인쇄 타입을 선택하세요.
      </p>
      <CheckboxGroup cardTypes={cardTypes} handleSelectType={handleSelectType} />
    </section>
  );
}

export default CardTypeSelect;
