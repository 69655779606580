import React from "react";

function NoticationDetailHeader({date, title}) {
  return (
    <header className="flex gap-8 items-center px-10 py-9 w-full leading-none border-b border-neutral-200 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col self-stretch my-auto min-w-[240px] max-md:max-w-full">
        <div className="flex flex-col max-md:max-w-full">
          <h2 className="text-base font-bold text-indigo-600">[공지사항]</h2>
          <h1 className="mt-4 text-xl font-medium text-zinc-800 max-md:max-w-full">
           {title}
          </h1>
        </div>
        <time className="mt-5 text-base text-neutral-700">
         {date}
        </time>
      </div>
    </header>
  );
}

export default NoticationDetailHeader;
