// endpoint authentication
export const AUTH_ENDPOINTS = {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    LOGIN_WITH_GOOGLE: "auth/google",
    GENERATE_OTP: "/auth/generate-otp",
    VERIFY_OTP: "/auth/verify-otp",
    UPDATE_USER_INFO: "/auth/update-user-info",
    CANCEL_USER_INFO: "/auth/cancel-member"

};

// endpoint other
export const ENDPOINTS = {
    BUSINESS_CARDS: "/business-cards",
    BUSINESS_CARDS_IMAGE: "/images",
    BUSINESS_CARDS_TYPE: "/business-card-orders/business-card-type",
    GET_FILE_FORMAT: "/business-card-orders/get-file-format",
    IMAGES_LIST_BY_TYPE: "/images/get-list-by-type",
    LOGOS: "/logos",
    LOGOS_LIST: "/logos/list-by-logo",
    LOGO_INFO_PAYMENTS: "/logos/info-payment",
    GET_FAQ: "/faqs/get-all",

    PAYMENTS_KAKAO_INITIALIZE: "/payments/kakao/initialize",
    PAYMENTS_TOSS_PAYMENT_INITIALIZE: "/payments/toss-payment/initialize",
    PAYMENTS_NAVER_PAY_INITIALIZE: "/payments/naver-pay/initialize",

    // order
    ORDERS: "/orders",
    ORDERS_LOGOS: "/orders/logos",
    ORDERS_BUSINESS_CARDS: "/orders/business-card",

    //images
    POST_IMAGE_TO_S3: "/images",

    //business card sprint
    GET_BUSINESS_CARD_ORDER_PRINTS: "/business-card-orders",
    BUSINESS_CARD_ORDERS_SPRINTS: "/business-card-orders/print",
    GET_BUSINESS_CARD_PRINTS_BY_TYPE:
        "/business-card-orders/config-information",
    POST_ORDER_PRINTS: "/orders/print",

    //expert-requests
   EXPERT_REQUESTS: "/expert-requests",
    //faqs
    GET_FAQS_ALL: "/faqs/get-all",

    //Notices
    GET_NOTICES: "/notices",

    //query 1:1

    USER_QUERY: "/user-queries",


    //alarms
    ALARM : "/alarms", 
    READ_ALL_ALARM:"/alarms/read-all-alarm",
    DELETE_ALL_ALARM:"/alarms/delete-all-alarm",

    //My page 
    GET_USER_INFO: "/users",
    GET_PAYMENT_ORDER:'/orders/get-all-order-for-user',
    HISTORY_ORDER_LOGO:"/orders/get-all-order-for-logo",
    HISTORY_ORDER_BUSINESS:"/orders/get-all-order-for-business-card",
    HISTORY_ORDER_PRINT_BUSINESS:"/orders/get-all-order-for-print",

    CANCEL_PAYMENT_LOGO:"/payments/cancel-logo",
    CANCEL_PAYMENT_BUSINESS_CARD:"/payments/cancel-business-card"


};
