import React from "react";
import ConsultationRequestCard from "./ConsultationRequestCard";
import { useNavigate } from "react-router-dom";

const ConsultationRequestList = ({ data, tab = 1 }) => {
    const navigate = useNavigate();

    const handleDetails = (id) => {
        navigate(`/experts/consultation/${id}`);
    };
    return (
        <main className="flex flex-col leading-none">
            <section className="grid grid-cols-1 items-center gap-6 lg:grid-cols-2">
                {data.map((request, index) => (
                    <div key={index} onClick={() => handleDetails(request.id)}>
                        {" "}
                        <ConsultationRequestCard
                            tab={tab}
                            key={index}
                            {...request}
                        />
                    </div>
                ))}
            </section>
        </main>
    );
};

export default ConsultationRequestList;
