import React from "react";
import Button from "../../../../components/common/Button/Button";
import NoticationDetailHeader from "./NoticationDetailHeader";
import NoticationDetailContent from "./NoticationDetailContent";
import { useNavigate, useParams } from "react-router-dom";
import { useGetNoticesDetailsQuery } from "../../../../services/apiSlice";
import { formatDateTime } from "../../../../utils/helper/formatters";

function NoticationDetailLayout() {
    const { id } = useParams();
        const navigate = useNavigate();
    
    const { data, isLoading, error } = useGetNoticesDetailsQuery({
        id: id,
    });

    console.log("dataadasdas", data);
    
    return (
        <div className="flex flex-col items-center px-20 max-md:px-5">
            <article className="flex w-full flex-col justify-center rounded-3xl border border-solid border-gray-200">
                {data && (
                    <>
                   
                        <NoticationDetailHeader
                            title={data?.title || ""}
                            date={data?.createdAt ? formatDateTime(data.createdAt) : "Unknown Date"}
                        />
                        <NoticationDetailContent content={data?.content||''}  />
                    </>
                )}
            </article>

            <div className="mt-4 flex w-full justify-center py-20">
                <Button
                    onClick={() => navigate("/customer-faq")}
                    label={"목록으로"}
                    className="min-h-[50px] w-[196px] min-w-[240px] rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                />
            </div>
        </div>
    );
}

export default NoticationDetailLayout;
